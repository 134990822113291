import { orElse } from "utils/objectUtils";
import { renderContent } from "../LandingPageModuleRenderer"

export const UseFromRootPageFeatureOverview = ({rootPage, importantOnly, showDescription, narrow, center}) => {

    showDescription = orElse(showDescription, false)
    narrow = orElse(narrow, true)
    center = orElse(center, true)

    let fo = rootPage.productPage?.content?.find(c=>c.type==='MediumSection')?.props.content.find(c=>c.type==='FeatureOverview');
    if (fo) {
        return renderContent([{
            ...fo,
            props: 
            {...fo.props, 
                narrow, 
                showDescription, 
                center,
                importantOnly,
            }
        }])
    }

    return null

}