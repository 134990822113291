import { Box } from "@mui/material";
import { ConditionalWrapper } from "modules/picasso-ui/ConditionalWrapper";
import { useIsMounted } from "modules/picasso-ui/form/useIsMounted";
import Image from "next/image";
import { useState } from "react";
import { orElse } from "utils/objectUtils";
import { TiltAnimation } from "../effect/TiltAnimation";

export const FramedResponsiveImage = ({src, width, height, layout, maxContainerHeight, showBorder, alt}) => {

    const [loaded, setLoaded] = useState(false)

    const isMounted = useIsMounted()

    const onLoadingComplete = () => {
        if (isMounted()) {
            setLoaded(true)
        }
    }

    showBorder = orElse(showBorder, true)

    const backgroundColor = '#f3f3f3' //loaded ? null : '#f3f3f3'
    const opacity = loaded ? 1 : 0

    return (
        <>
        <TiltAnimation> {/** important: must wrap the box because the animation should start when box is rerendered */}
            <ConditionalWrapper
                wrapper={(c)=><Box
                    style={{
                        borderRadius: '25px',
                        //border: '1px solid red',
                        overflow: 'hidden',
                        boxShadow: 'rgb(100 100 111 / 20%) 0px 7px 29px 0px',
                        position: 'relative',
                        //height: '50vh',
                        border: '1px solid #f3f3f3',
                        maxHeight: maxContainerHeight || null,
                        opacity: opacity,
                        backgroundColor: backgroundColor,
                    }}
                    mb={15}
                    className="effect-shine"
                    >{c}</Box>}
                condition={showBorder}
            >
                <Image src={src} 
                    layout={layout || 'responsive'}
                    //objectFit="cover"
                    width={width} 
                    height={height} 
                    sizes="50vw"
                    quality={100}
                    onLoadingComplete={onLoadingComplete}
                    priority={true}    
                    alt={alt}                
                    />
                </ConditionalWrapper>
        </TiltAnimation>
       </>
    )

}