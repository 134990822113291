
import { Typography, Grid, Box } from '@mui/material';
import { Avatar } from 'components/present/Avatar';
import { TypoLandingHeading } from 'modules/landingPage/TypographyLanding';

export const LandingPersons = ({items}) => {

    const renderItems = () => {
        return items.map((i, idx) => {
            return <Grid item key={idx} md={6}>
                <Box textAlign="center" pb={5}>
                    <Box mb={1}><Avatar image={i.image} /></Box>
                    <Box><Typography style={{fontSize: '21px'}}>{i.name}</Typography></Box>
                    <Box mb={3}><TypoLandingHeading size="6">{i.subtitle}</TypoLandingHeading></Box>
                    <Box color="text.secondary"><Typography>{i.text}</Typography></Box>
                </Box>
            </Grid>
        })
    };

    return (<Grid container justifyContent="center" spacing={6}>
        {renderItems()}
    </Grid>);
}