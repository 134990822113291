import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { TypoLandingHeading } from 'modules/landingPage/TypographyLanding';
import { Avatar } from './Avatar';
import { RatingStars } from "modules/picasso-ui/present/RatingStars"


const useStyles = makeStyles((theme) => ({
    root: {

    },
    name: {
        color: theme.palette.primary.main,
        fontSize: '21px'
    },
    text: {
        letterSpacing: '2'
    },
}));

export const Testimonial = ({ name, subtitle, text, image }) => {

    const classes = useStyles();

    return (
        <Box>
            <Box><Avatar image={image} /></Box>
            <Box mb={1}><Typography className={classes.name}>{name}</Typography></Box>
            <div><TypoLandingHeading size="6">{subtitle}</TypoLandingHeading></div>
            <Box mt={4} mb={1}><Typography className={classes.text}>{text}</Typography></Box>
            <RatingStars />
        </Box>
    );

}