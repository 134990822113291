import { Box, Grid, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { ChipStyled } from "modules/picasso-ui/present/chip/ChipStyled";
import Image from "next/image";
import { Fragment } from "react";
import { orElse } from "utils/objectUtils";

const useStyles = makeStyles((theme)=>({
    grid: {
        marginBottom: theme.spacing(4),
        [theme.breakpoints.down('md')]: {
            width: '100% !important', //reset spacing
        }
    },
    categoryTitle: {
        fontSize: '16px',
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(3),
    },
    title: {
        fontWeight: '600',
        fontSize: '18px', 
    },
    label: {
        backgroundColor: theme.palette.secondary.main,
        color: '#fff',
    },
    text: {
        fontSize: '15px', 
        fontWeight: '500',
    },
}))

export const FeatureOverview = ({items,center, narrow, showDescription, importantOnly}) => {

    const classes = useStyles()

    showDescription = orElse(showDescription, true)

    const renderLabels = (labels) => {
        return labels.map((l,idx)=>{
            return <Box key={idx}><ChipStyled label={l} classes={{chip:classes.label}}/></Box>
        })
    }

    const renderItems = (items) => items.map((i,idx)=>{
        return (<Grid item key={idx} xs={12} sm={6} md={4}>
            <Box mb={narrow === true? 0:4} textAlign={center===true?'center':null}>
                <Box mb={narrow === true? 1:4}>
                    <Image src={i.imgSrc} width={50} height={50} />
                </Box>
                <Typography variant="h3" className={classes.title}>{i.title}</Typography>
                {showDescription && (
                    <Box color="text.secondary" mt={1}>
                        <Typography className={classes.text}>{i.description}</Typography>
                    </Box>
                )}
                {i.labels && i.labels.length > 0 && <Box mt={1}>{renderLabels(i.labels)}</Box>}
            </Box>
        </Grid>)
    })

    const renderCategoryTitle = (title) => {
        return <Grid item xs={12} className={classes.categoryTitle} style={{ textAlign: center===true?'center':null  }}>
                <Typography variant="h2">{title}</Typography>
            </Grid>
    }

    const renderItemsByCategory = () => {
        let renderedElems = []

        let itemsByCategory = {}
        let withoutCategory = []

        let itemsFiltered = []

        if (importantOnly !== true) {
            itemsFiltered = items
        } else {
            itemsFiltered = items.filter(i=>i.important === true)
        }

        itemsFiltered.forEach(i=>{
            if (importantOnly !== true && i.category) {
                itemsByCategory[i.category] = itemsByCategory[i.category] || []
                itemsByCategory[i.category].push(i)
            } else {
                withoutCategory.push(i) //if importantOnly, make all have no category
            }
        })

        Object.keys(itemsByCategory).forEach(k=>{
            renderedElems.push(<Fragment key={'cat_'+k}>
                {renderCategoryTitle(k)}
                {renderItems(itemsByCategory[k])}
            </Fragment>)
        })

        renderedElems.push(<Fragment key="withoutCategory">{renderItems(withoutCategory)}</Fragment>)

        return renderedElems
    }

    return (
        <Grid container spacing={8} className={classes.grid}>
            {renderItemsByCategory()}
        </Grid>
    )

}