import { useEffect } from "react";
import Script from 'next/script'

export const LandingPageSnippet = ({init, remove}) => {

    useEffect(() => {
        return () => {
            if (remove) {
                new Function(remove)();
            }
        };
    }, []);

    return (
            <Script
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: `
                        setTimeout(function(){
                            try {
                                ${init}
                            } catch (e) {
                                console.error(e);
                            }
                        }, 500)
                    `,
                }}//add delay specifically for vsprio init / remove, so that init is not too fast
            />
    )

}