import { FormattedTypography } from "modules/picasso-ui/present/text/FormattedText";

const styleDef = {
    text: theme=>({
        fontSize: '24px',
        '&+&': {
            marginTop: theme.spacing(4),
        }
    })
}

export const LandingCustomerQuotesSimple = ({items}) => {

    return (
        <div>
            {items?.map(({text, idx})=><FormattedTypography key={idx} sx={styleDef.text}>{text}</FormattedTypography>)}
        </div>
    )

}