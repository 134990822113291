
import makeStyles from '@mui/styles/makeStyles';
import { orElse } from 'utils/objectUtils';

const useStyles = makeStyles((theme) => ({
    imageWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'center',
      overflow: 'hidden',
      position: 'relative',
      height: '100%',
      [theme.breakpoints.down('md')]: {
        maxWidth: '340px',
        maxHeight: '500px',
        '& img': {
          maxWidth: '340px',
          maxHeight: '500px',
        },
    },
      [theme.breakpoints.down('sm')]: {
          maxWidth: '240px',
          maxHeight: '500px',
          '& img': {
            maxWidth: '240px',
            maxHeight: '500px',
          },
      },
      '& img': {
        objectFit: 'contain',
        margin: '0 auto'
      }
    },
    imageWrapperFullWidth: {
      width: '100%',
    },
    imageCenter: {
        //left: '50%',
        //left: 0,
        //top: '0',
        //position: 'absolute',
        //marginLeft: '50%',
        //transform: 'translateX(-50%)',
    }
  }));

export const LandingImage = ({src, alt, width, height, imageProps, fullWidth}) => {
    
    const classes = useStyles();

    fullWidth = orElse(fullWidth, true)

    let wrapperClassName = [classes.imageWrapper]
    if (fullWidth === true) {
      wrapperClassName.push(classes.imageWrapperFullWidth)
    }

    return (
        <div className={wrapperClassName.join(' ')}>
            <div className={classes.imageCenter}>
                <img src={src} alt={alt} width={width} height={height} {...imageProps} 
                //layout="intrinsic" 
                />
            </div>
        </div>
    );
}


