
import { Box, Typography, } from '@mui/material';
import { ImageCss } from 'components/app/ImageCss';
import { GridDefault, GridDefault4 } from 'components/layout/positioning/GridDefault';
import { MultilineText } from 'modules/picasso-ui/present/MultilineText';
import Image from 'next/image';

export const LandingPageValuePropositionsImagesRound = ({items, id}) => {

    const renderItems = () => {
        return items.map((i, idx)=>{

            const { objectPosition } = i

            return (
                <Box key={idx}
                    sx={(theme)=>({
                        cursor: 'default',
                        padding: '12px 0px 16px 0px', 
                        height: '100%',
                        maxWidth: '360px',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        [theme.breakpoints.down('sm')]: {
                            background: 'rgb(229 244 242 / 40%)', 
                            //boxShadow: `-12px 0px 0px 0px #f8fafb, 12px 0px 0px 0px #f8fafb`,
                            padding: '12px 12px 32px 12px', 
                        },
                        [theme.breakpoints.up('sm')]: {
/*                             ['&:hover']: {
                                background: 'rgb(229 244 242 / 40%)',//'#f8fafb', 
                                boxShadow: `-12px 0px 0px 0px rgb(229 244 242 / 40%), 12px 0px 0px 0px rgb(229 244 242 / 40%)`,
                            }, */
/*                             ['& .lpv-description']: {
                                visibility: 'hidden',
                            }, */
                        },
/*                         ['&:hover .lpv-description']: {
                            visibility: 'visible',
                        }, */
                        borderRadius: '10px', 
                    })}
                >
                    <Box sx={{
                        //background: '#f8fafb', 
                        borderRadius: '10px', 
                        //paddingBottom: '48px', 
                        textAlign: 'center', 
                        }}
                        >
                        {i.imgSrc && 
                            <Box display="flex" justifyContent="center" mb={4} sx={{
                                width: '100%',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                //backgroundColor: '#f8fafb', 
                                }}>
                                    <Box 
                                        sx={{
                                        borderRadius: '20px', 
                                        //border: '6px solid #f8fafb', 
                                        //display: 'inline-flex' 
                                        position: 'relative',
                                        height: '190px',
                                        maxWidth: '360px',
                                        overflow: 'hidden',
                                        width: '100%',
                                        backgroundColor: 'rgb(229 244 242)',
                                        //border: '15px solid rgb(245 255 254)',
                                        }}
                                        //p={2}
                                        >
                                        <ImageCss 
                                            image={i.imgSrc} 
                                            //width="190" 
                                            //height="190"
                                            imageElemProps={{
                                                css: {
                                                    backgroundSize: '120%',
                                                    backgroundPosition: 'center center',
                                                }
                                            }}
                                            css={{
                                                //border: '10px solid #f8fafb', 
                                                borderRadius: '10px', 
                                            }}
/*                                             unoptimized 
                                            loading="lazy"
                                            objectFit="contain"
                                            objectPosition={objectPosition}
                                            layout="fill" */
                                            
                                        />
                                    </Box>
                            </Box>
                        }
                        <Box>
                            <Box textAlign="center">
                                <Typography sx={{fontWeight: 500, fontSize: '1.3em',}}><MultilineText>{i.title}</MultilineText></Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box textAlign="center" mt={2} className="lpv-description" pb={4}>
                        <Typography sx={{color: 'text.secondary', fontSize: '1.1em',}}>{i.text}</Typography>
                    </Box>
                </Box>
            )
        })
    }

    return (
        <Box id={id} pt={6} pb={8}>
            <GridDefault4>
                {renderItems()}
            </GridDefault4>
        </Box>
    )

}
