import { Box } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Link from "next/link"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const useStyles = makeStyles((theme)=>({
    link: {
        color: theme.palette.text.primary,
        '&:active,&:focus,&:hover': {
            color: theme.palette.text.primary,
        },
        fontSize: '20px',
        fontWeight: '500',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));

export const ReadMoreLink = ({text, href, target, showArrow}) => {

    const classes = useStyles()

    return (
        <Box textAlign="center" pt={1.5} pb={1.5}> 
            <Link href={href}><a className={classes.link} target={target}>{text} {showArrow !== false && <ArrowForwardIcon />}</a></Link>
        </Box>
    )

}