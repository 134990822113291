import { Box } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Landing } from "components/layout/partials/Landing";
import { renderContent } from "modules/landingPage/LandingPageModuleRenderer";
import { SlideUpAnimation } from "modules/picasso-ui/present/effect/SlideUpAnimation";
import { TiltAnimation } from "modules/picasso-ui/present/effect/TiltAnimation";
import { MainIncentiveLink } from "modules/landingPage/component/saas/MainIncentiveLink";
import { FramedResponsiveImage } from "modules/picasso-ui/present/image/FramedResponsiveImage";
import { isValidElement } from "react";

const useStyles = makeStyles((theme)=>({
    root: {
        paddingTop: '12vh',
        textAlign: 'center',
        marginBottom: theme.spacing(6),
        [theme.breakpoints.down('md')]: {
            paddingTop: '12vh',
            marginBottom: theme.spacing(22), // when row layout, the margin is always enough, but not in xs
        }
    },
    grid: {
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column-reverse',
            alignItems: 'center',
            width: '100% !important',
            margin: '0',
        },
        [theme.breakpoints.up('md')]: {
            alignItems: 'center',
        }
    },
    secondaryContent:{
        marginTop: theme.spacing(8),
        [theme.breakpoints.down('md')]: {
            width: '100% !important',
        }
    }
}))


/**
 * linear-gradient(to bottom, #cfd6ff, #e1e7ff, #f8fbff, #ffffff 700px)
 * 
 * linear-gradient(to bottom, #cfd6ff, #e1e7ff, #f8fbff, #ffffff 1200px)
 * 
 * color: #3d486a
 * 
 * 
 * linear-gradient(to bottom, #edf3ff, #fdfeff, #f8fbff, #ffffff 1200px)
 * 
 * 
 * @param {*} param0 
 * @returns 
 */
//mainIncentiveLink => deprecated, use content instead
export const HeroCentered1 = ({id, mainTitle, mainSubtitle, mainIncentiveLink, image, incentiveContent, secondaryContent, minHeight, animate, bold, fontWeight}) => {
    
    const classes = useStyles()

    const incentiveContentRendered = incentiveContent ? renderContent(incentiveContent, false) : null
    const secondaryContentRendered = secondaryContent ? (isValidElement(secondaryContent) ? secondaryContent : renderContent(secondaryContent, false)) : null

    const renderPrimaryInner = () => <Landing 
        textAlign="center"
        heading={mainTitle}
        subtitle={mainSubtitle}
        bold={bold}
        fontWeight={fontWeight}
        incentive={<>
            {incentiveContentRendered && (
                <Box display="flex" justifyContent="center">
                    <Box>
                        {incentiveContentRendered}
                    </Box>
                </Box>
            )}
            {mainIncentiveLink && (
                <Box display="flex" justifyContent="center">
                    <Box>
                        <MainIncentiveLink 
                            {...mainIncentiveLink}
                            buttonProps={{size: 'large'}}
                        />
                    </Box>
                </Box>
            )}
        </>}
    />

    return (
            <Box 
                style={{minHeight: minHeight || '72vh'}}
                className={classes.root}
                id={id}
            >

               {/*  <BodyBackgroundLandingGradient /> */}

                        {animate ? 
                            <SlideUpAnimation>
                                <div>
                                    {renderPrimaryInner()}
                                </div>
                            </SlideUpAnimation>
                            :
                            renderPrimaryInner()
                        }


                    <div className={classes.secondaryContent}>
                        {image && (
                            <FramedResponsiveImage {...image} />
                        )}
                        {secondaryContentRendered && 
                            (animate !== false ? 
                                <TiltAnimation>
                                    <Box textAlign="center">
                                        {secondaryContentRendered}
                                    </Box>
                                </TiltAnimation>
                                :
                                secondaryContentRendered
                            )
                        }
                    </div>
                
            
            </Box>
    );

}