import StarRateIcon from '@mui/icons-material/StarRate';

const styleDef = {
    root: { color: '#f0ad4e' },
    icon: { fontSize: '30px' }
}

export const RatingStars = () => {

    return (
        <div css={styleDef.root}><StarRateIcon sx={styleDef.icon} /><StarRateIcon sx={styleDef.icon} /><StarRateIcon sx={styleDef.icon} /><StarRateIcon sx={styleDef.icon} /><StarRateIcon sx={styleDef.icon} /></div>
    )

}