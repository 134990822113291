import { FormControl, Select } from "@mui/material"
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { isHavingValue } from "utils/objectUtils";
import { setMyLocale } from "../myLocaleService";

export const LanguageSwitch = ({languages, MenuProps}) => {

    const { i18n, t } = useTranslation('yoio')

    const router = useRouter()

    const handleChange = (e) => {
        const val = e.target.value
        if (isHavingValue(val)) {
            setMyLocale(val)
            router.reload(window.location.pathname)
        }
    }

    const renderOptions = () => {
        return languages.map((l, idx)=><option key={idx} value={l}>{t(`i18n.locales.${l}`)}</option>)
    }

    return (
        <>
        <FormControl>
            <Select
                native
                defaultValue={i18n.language}
                onChange={handleChange}
                inputProps={{
                    name: 'language',
                    style:{minWidth: '55px', fontSize: '14px', lineHeight: '16px'}
                }}
                MenuProps={MenuProps}
                margin="dense"
                disableUnderline={true}
                //variant="outlined"
            >
                {renderOptions()}
            </Select>
        </FormControl>
        </>
    )

}   