import { css } from "@emotion/react"

const style = css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '-20px',
    '& img': {
        marginLeft: '-8px', border: '2px solid #fff', borderRadius: '100px',
    },
    '& img:first-of-type': {
       //marginLeft: '4px'
    },
    '&::before': {
        content: '""',
        width: '8px',
    },
    transform: 'scaleX(-1)',
})

export const LandingAvatarGroup = ({avatarSize}) => {

    avatarSize = avatarSize || 65

    const items = [
        {
            imgSrc: '/yoio/users/demoAvatarUser01.jpg'
        },
        {
            imgSrc: '/yoio/users/demoAvatarUser02.jpg'
        },
        {
            imgSrc: '/yoio/users/demoAvatarUser03.jpg'
        },
        {
            imgSrc: '/yoio/users/demoAvatarUser04.jpg'
        },
        {
            imgSrc: '/yoio/users/demoAvatarUser05.jpg'
        },
        {
            imgSrc: '/yoio/users/demoAvatarUser06.jpg'
        },
    ]

    const itemSx = {height: avatarSize+'px', width: avatarSize+'px' }

    // Must be lightweight, no heavy library
    return (
        <div css={style}>
            {items.reverse().map((i,idx)=><img key={idx} alt="Avatar of user" src={i.imgSrc} css={itemSx} height={avatarSize} width={avatarSize} loading="lazy" />)}
        </div>
    )

}