import { Box, Container, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import { FormattedTypography } from "modules/picasso-ui/present/text/FormattedText";

const styleDef = {
    root: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
    },
    title: {
        fontSize: '2.4em',
        fontWeight: '600',
        lineHeight: '1.2em',
    },
}

export const LandingFullWidthBanner = ({title, subtitle, textAlign, contentMaxWidth, py, background, backgroundImage, color}) => {

    const theme = useTheme()
    const largeScreen = useMediaQuery('(min-width:850px)')

    const renderInner = () => {
        return <>
            <FormattedTypography component="h2" sx={styleDef.title} textAlign={textAlign} color={color}>{title}</FormattedTypography>
            {subtitle && <FormattedTypography color="text.secondary" textAlign={textAlign} mt={1} fontSize="1.2em" fontWeight="500">{subtitle}</FormattedTypography>}
        </>
    }

    backgroundImage=largeScreen ? backgroundImage:null

    const contentStyles = {
        py: py??theme.spacing(5),
        backgroundImage,
        backgroundSize: '320px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right 24px bottom -5px',
    }

    return (
        <Box sx={[styleDef.root,{background:background??'linear-gradient(90deg, rgb(102 160 255) 0%, rgb(62 135 255) 100%)'}, !contentMaxWidth?contentStyles:null]} textAlign={textAlign} justifyContent={textAlign==='left'?'flex-start':'center'}>
            {contentMaxWidth
                ? <Container maxWidth={contentMaxWidth} sx={[contentMaxWidth?contentStyles:null]}>{renderInner()}</Container>
                : renderInner()
            }
            
        </Box>
    )

}