import { Box, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { FormattedText } from "modules/picasso-ui/present/text/FormattedText"
import Image from "next/image"

const useStyles = makeStyles(()=>({
    root: {
        textAlign: 'center',
    },
    text: {
        fontSize: '18px',
    },
}))

export const LandingIncentiveInPage = ({title, text, imageSrc}) => {

    const classes = useStyles()

    return (
        <div className={classes.root}>
            {imageSrc && 
                <div style={{paddingBottom: '10px'}}>
                    <Image src={imageSrc} width="60px" height="60px" />
                </div>
            }
            {title && <div>
                <Typography variant="h2" style={{fontSize: '28px'}}>{title}</Typography>
            </div>}
            {text && 
                <Box color="text.primary" mt={imageSrc || title ? 5 : 3} mb={3}>
                    <Typography className={classes.text}><FormattedText>{text}</FormattedText></Typography> 
                </Box>
            }
        </div>
    )

}