import { TypoLandingHeading } from "modules/landingPage/TypographyLanding";
import { Box, Typography } from '@mui/material';


export function LandingHeadingPartial(props) {
    return (
        <Box textAlign="center">
            <TypoLandingHeading {...props}/>
        </Box>
    );
}

export const LegalPageH1 = ({children}) => {
    return (
        <Box mb={2} mt={5} align="left">
            <Typography variant="h1" style={{fontWeight: '600', fontSize: '3.0em'}}>{children}</Typography>
        </Box>
    );
}

export const LandingTitleLineSimple = ({children}) => {
    return (
        <Box mb={2} mt={5}>
            <strong>{children}</strong>
        </Box>
    );
}

export const LandingTitleLineH2Simple = ({children}) => {
    return (
        <Box mb={2} mt={5} color="text.primary">
            <Typography variant="h2" component="h2"  style={{fontWeight: '600', fontSize: '1.5em'}}>{children}</Typography>
        </Box>
    );
}

export const LandingTitleLineH3Simple = ({children, component}) => {
    return <Typography component={component || "h3"} sx={{ color: 'text.primary', mt: 5, mb: 2, fontWeight: '600', fontSize: '1.1em' }}>{children}</Typography>
}

export const TermsSectionTitle = ({children}) => {
    return <LandingTitleLineH3Simple component="h2">{children}</LandingTitleLineH3Simple>
}