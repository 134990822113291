import { ToggleButton, ToggleButtonGroup, toggleButtonGroupClasses, ToggleButtonGroupProps } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

const stylesDef = {
    group: ()=>({
        backgroundColor: '#eff2f5',
        padding: '3px',
        borderRadius: '5px',
        [`& .${toggleButtonGroupClasses.grouped}`]: {
            border: 'none',
            paddingTop: '0px',
            paddingBottom: '0px'
        },
    }),
    groupRound: {
        borderRadius: '55px !important',
    },
    groupExtraLarge: {
        '& button': {
            padding: '10px 30px !important',
        }
    },
    groupButtonsDepth: {
        '& button.Mui-selected': {
            boxShadow: '0 0.1875rem 0.375rem rgb(140 152 164 / 25%)',
        }
    },
    button: theme=>({
        textTransform: 'none',
        borderRadius: '5px',
        padding: '3px 11px',
        color: theme.palette.text.secondary,
        '&:not(:last-child)': {
            marginRight: '3px',
        },
        [`&.Mui-selected`]: {
            backgroundColor: '#fff !important',
            color: theme.palette.text.primary,
        }
    }),
    buttonRound: {
        borderRadius: '55px !important',
    },
}

export interface StyledToggleButtonGroupContainedProps extends ToggleButtonGroupProps {
    round?: boolean
    extraLarge?: boolean
    depth?: boolean
    sx?: any
}

export const StyledToggleButtonGroupContained: FC<PropsWithChildren<StyledToggleButtonGroupContainedProps>> = ({round, extraLarge, depth, sx, ...props}) => {
    const styles = [stylesDef.group] as any[]
    if (round) { styles.push(stylesDef.groupRound) }
    if (extraLarge) { styles.push(stylesDef.groupExtraLarge) }
    if (depth) { styles.push(stylesDef.groupButtonsDepth) }
    if (sx) {
        styles.push(sx)
    }
    return <ToggleButtonGroup {...props} sx={styles}/>
}

export const StyledToggleButtonContained = ({round, ...props}) => {

    const styles = [stylesDef.button] as any[]
    if (round) { styles.push(stylesDef.buttonRound) }
    styles.push(props.sx)

    //@ts-ignore
    return <ToggleButton {...props} sx={styles} />
}