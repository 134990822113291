import { css, Typography } from "@mui/material"
import { FormattedTypography } from "modules/picasso-ui/present/text/FormattedText"
import { FunctionComponent } from "react"
import { HeroProps } from "./HeroTypes"

const styles = {
    root: theme=>({
        '--shadow-effect-color': 'rgba(18, 27, 165, 0.12)',
        '--shadow-effect-height': '15vw',
        '--shadow-effect-width': '10vw',
        '--shadow-effect-shift': '2vw',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 'max(70px, 12vh)',
        position: 'relative',
/*         '&::before': {
            position: 'absolute',
            transform: 'translateX(-100%)',
            left: '0',
            content: '""',
            width: 'var(--shadow-effect-width)',
            background: 'white',
            height: 'var(--shadow-effect-height)',
            filter: 'drop-shadow(var(--shadow-effect-color) calc(var(--shadow-effect-width) - var(--shadow-effect-shift)) 88px 48px)',
            borderRadius: '99px',
        },
        '&::after': {
            position: 'absolute',
            transform: 'translateX(-100%)',
            left: '0',
            content: '""',
            width: 'var(--shadow-effect-width)',
            background: 'white',
            height: 'var(--shadow-effect-height)',
            filter: 'drop-shadow(var(--shadow-effect-color) calc(100vw + var(--shadow-effect-shift)) 88px 48px)',
            borderRadius: '99px',
        } */
    }),
    rootFullHeight: ()=>({
        height: '100%',
        maxHeight: '100%',
        paddingTop: 0, //Remove padding and align vertically
        justifyContent: 'center',
        alignItems: 'stretch',
    }),
    rootWithoutEndContent: theme=>({
        paddingBottom: theme.spacing(7) + '',
    }),
    rootCard: theme=>({
        background: theme.palette.gradient,
        borderRadius: '25px',
        padding: 'min(6vh, 64px) min(4vw, 64px)',
        [theme.breakpoints.down('sm')]: {
            padding: '24px',
        },
    }),
    label: theme=>({
        textTransform: 'uppercase',
        fontSize: '1.1em',
        color: theme.palette.primary.main,
        fontWeight: 600,
        marginBottom: theme.spacing(2.2),
    }),
    title: theme=>({
        maxWidth: theme.breakpoints.values.lg,
        marginBottom: theme.spacing(3),
        lineHeight: '1.3em',
        display: 'block !important',
    }),
    titleAlignCenter: ()=>({
        marginLeft: 'auto',
        marginRight: 'auto',
    }),
    subtitle: theme=>({
        display: 'inline-block',
        color: theme.palette.text.secondary,
        lineHeight: '28.5px',
        width: '100%',
    }),
    subtitleCenter: (theme)=>({
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: theme.breakpoints.values.md,
    }),
    endContentWrapper: theme=>({
        marginTop: theme.spacing(5.5),
    }),
}

export const HeroCentered2: FunctionComponent<HeroProps & { variant?: 'card' }> = ({align, title, subtitle, titleFontWeight, label, secondaryContent, startContent, endContent, fullHeight, style, subtitleStyle, variant}) => {

    align = align || 'center'

    const rootStyle = [
        styles.root,
        fullHeight? styles.rootFullHeight:null, 
        endContent?null:styles.rootWithoutEndContent,
        align === 'center' ? { textAlign: 'center' } : null,
        style?css(style):null,
        variant === 'card'? styles.rootCard:null
    ] as any

    return (
            <div css={rootStyle}>
                {startContent && 
                    <div>{startContent}</div>
                }
                {label && <Typography component="div" sx={styles.label}>{label}</Typography>}
                {/** @ts-ignore */}
                <FormattedTypography variant="h1" component="h1" fontSize="3.5em" fontWeight={titleFontWeight || 600} sx={[styles.title, align === 'center'? styles.titleAlignCenter:null]}>{title}</FormattedTypography>
                {/** @ts-ignore */}
                {subtitle && <FormattedTypography component="span" fontSize="1.1em" sx={[styles.subtitle, align === 'center' ? styles.subtitleCenter : null, subtitleStyle]} className="subtitle">{subtitle}</FormattedTypography>}
                {secondaryContent && 
                    <div css={styles.endContentWrapper}>{secondaryContent}</div>
                }
                {endContent && 
                    <div css={styles.endContentWrapper}>{endContent}</div>
                }
            </div>
    )

}