import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Image from "next/image"


const useStyles = makeStyles((theme)=>({
    badge: {
        display: 'flex',
        color: '#f4f4f4',
        width: '258px',
        borderRadius: '5px',
        //boxShadow: '0px 0px 60px #eaeaea',
        boxShadow: '0px 0px 10px rgb(231 231 231 / 56%)',
        overflow: 'hidden',
        height: '65px',
        marginBottom: '1.5rem',
    },
    icon: {
        background: '',
        backgroundSize: 'contain',
        width: '70px',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    iconWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    textSection: {
        paddingRight: '20px',
        paddingLeft: '20px',
        color: theme.palette.text.primary,
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        textTransform: 'uppercase',
        marginTop: '3px',
        fontWeight: '600',
        lineHeight: 1,
        color: '#313135',
        fontSize: '12px',
        marginBottom: '-3px'
    },
    subtitle: {
        color: '#8e919b',//theme.palette.text.secondary,
        textTransform: 'uppercase',
        marginTop: '2px',
        display: 'inline-block',
        fontSize: '12px',
        lineHeight: 1,
        fontWeight: '500',
    }
}))

export const TrustBadge = ({icon, iconSrc, title, subtitle, badgeColor, iconWidth, iconHeight, iconOpacity}) => {

    const classes = useStyles()

    const renderIcon = () => {
        if (icon) {
            return icon;
        }

        if (iconSrc) {
            return <div className={classes.iconWrapper} style={{opacity: iconOpacity}}><Image src={iconSrc} loading="lazy" unoptimized="true" width={iconWidth || "50px"} height={iconHeight || "50px"} 
            /></div>
        }

        return null;
    }

    return (
        <div className={classes.badge}>
            <div className={classes.icon} style={{backgroundColor: badgeColor}}>{renderIcon()}</div>
            <div className={classes.textSection}>
                <div>
                    <Typography className={classes.title}>{title}</Typography>
                    <Typography className={classes.subtitle}>{subtitle}</Typography>
                </div>
            </div>
        </div>
    )

}

export const AdvancedDataProtectionBadge = () => {

    return <TrustBadge 
            title="PROTECTED DATA" 
            subtitle="SSL &amp; Encrpytion" 
            badgeColor="rgb(101 193 121)"
            iconSrc={'/badges/protectedData.svg'}
            iconWidth="40px"
            iconHeight="40px"
            />

}

export const  LiveSupportBagde = () => {

    return <TrustBadge 
            title="LIVE SUPPORT" 
            subtitle="Fast response time" 
            badgeColor="#e8c75d"
            iconSrc={'/badges/liveSupport.svg'}
            iconWidth="40px"
            iconHeight="40px"
            iconOpacity={0.4}
            />

}

export const  EuDatacenterBagde = () => {

    return <TrustBadge 
            title="Secure datacenter" 
            subtitle="GERMANY HOSTED" 
            badgeColor="#214fe0"
            iconSrc={'/badges/euDatacenter.svg'}
            iconWidth="40px"
            iconHeight="40px"
            />

}
