import { Box, Grid } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Landing } from "components/layout/partials/Landing";
import { renderContent } from "modules/landingPage/LandingPageModuleRenderer";
import { BodyBackgroundLandingGradient } from "modules/picasso-ui/layout/BackgroundGradient";
import { SlideUpAnimation } from "modules/picasso-ui/present/effect/SlideUpAnimation";
import { TiltAnimation } from "modules/picasso-ui/present/effect/TiltAnimation";
import { FramedResponsiveImage } from "modules/picasso-ui/present/image/FramedResponsiveImage";
import { MainIncentiveLink } from "./MainIncentiveLink";

const useStyles = makeStyles((theme)=>({
    root: {
        paddingTop: '12vh',
        [theme.breakpoints.down('md')]: {
            paddingTop: '12vh',
            marginBottom: theme.spacing(22), // when row layout, the margin is always enough, but not in xs
        }
    },
    grid: {
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column-reverse',
            alignItems: 'center',
            width: '100% !important',
            margin: '0',
        },
        [theme.breakpoints.up('md')]: {
            alignItems: 'center',
        }
    },
    secondaryContent:{
        [theme.breakpoints.down('md')]: {
            width: '100% !important',
        }
    }
}))


/**
 * linear-gradient(to bottom, #cfd6ff, #e1e7ff, #f8fbff, #ffffff 700px)
 * 
 * linear-gradient(to bottom, #cfd6ff, #e1e7ff, #f8fbff, #ffffff 1200px)
 * 
 * color: #3d486a
 * 
 * 
 * linear-gradient(to bottom, #edf3ff, #fdfeff, #f8fbff, #ffffff 1200px)
 * 
 * 
 * @param {*} param0 
 * @returns 
 */
//mainIncentiveLink => deprecated, use content instead
export const LandingSaas = ({mainTitle, mainSubtitle, mainIncentiveLink, image, secondaryContent}) => {
    
    const classes = useStyles()

    const secondaryContentRendered = secondaryContent ? renderContent(secondaryContent, false) : null

    return (
            <Box 
                style={{minHeight: '72vh'}}
                className={classes.root}
            >

                <BodyBackgroundLandingGradient />

                <Grid container spacing={5} justifyContent="space-between" className={classes.grid}>

                    <Grid item xs={12} md={6}>
                        <SlideUpAnimation>
                            <div>
                                <Landing 
                                    heading={mainTitle}
                                    subtitle={mainSubtitle}
                                    incentive={<>
                                        {mainIncentiveLink && (
                                            <Box display="flex" justifyContent="flex-start">
                                                <Box>
                                                    <MainIncentiveLink 
                                                        {...mainIncentiveLink}
                                                        buttonProps={{size: 'large'}}
                                                    />
                                                </Box>
                                            </Box>
                                        )}
{/*                                         {getInvitationFieldDef && (
                                            <GetInvitationField {...getInvitationFieldDef.props} />
                                        )} */}
                                    </>}
                                />
                            </div>
                        </SlideUpAnimation>
                    </Grid>

                    <Grid item xs={12} md={6} className={classes.secondaryContent}>
                        {image && (
                            <FramedResponsiveImage {...image} maxContainerHeight="50vh" />
                        )}
                        {secondaryContentRendered && (
                            <TiltAnimation>
                                <Box textAlign="center">
                                    {secondaryContentRendered}
                                </Box>
                            </TiltAnimation>
                        )}
                    </Grid>
                </Grid>
                
            
            </Box>
    );

}