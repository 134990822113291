import { useTranslation } from "next-i18next"

export interface UseLocaleReturn {
    localesAvailable: {title: string, locale: string}[]
}

export const useLocale = ():UseLocaleReturn => {

    const { t } = useTranslation('yoio')

    const localesAvailable = ['en', 'de']

    return {
        localesAvailable: localesAvailable.map(l=>({locale: l, title: t(`i18n.locales.${l}`)}))
    }

}