import { css, Typography } from "@mui/material";
import { FormattedTypography } from "modules/picasso-ui/present/text/FormattedText";
import { FunctionComponent } from "react";

export interface LandingCustomerLogoProps {
    title?: string
    subtitle?: string
    items: {src: string, style?: any, width?: string }[],
    grey?: boolean
    logoWidth?: string
    titleStyle?: any
    logoContStyle?: any
}

const styleDef = {
    cont: theme=>css({
        display: 'flex',
        justifyContent: 'space-between',
        padding: '12px 0 40px 0',
        gap: '24px',
        flexWrap: 'wrap',
        '& img': {
            objectFit: 'contain',
        },
        [theme.breakpoints.down('md')]: {
            padding: '40px 24px',
            justifyContent: 'center',
            // Reason: otherwise looks bad on small screen
            '& img': {
                width: '100px',
            }
        },
    }),
    contGrey: css({
        filter: 'grayscale(1) brightness(1%) contrast(1)',
        opacity: '0.44',
    }),
}

export const LandingCustomerLogos: FunctionComponent<LandingCustomerLogoProps> = ({title, subtitle, items, grey, logoWidth, titleStyle, logoContStyle}) => {

    logoWidth = logoWidth || '128px'

    return (
        <>
            {/** @ts-ignore */}
            {title && <FormattedTypography component="div" textAlign="center" color="text.secondary" pt={9} sx={[{fontSize: '2.0em'}, titleStyle]}>{title}</FormattedTypography>}
            {subtitle && <Typography component="div" textAlign="center" color="text.secondary" mt={2} mb={3}>{subtitle}</Typography>}
            <div css={[styleDef.cont, grey?styleDef.contGrey:null, logoContStyle]}>
                {items?.map((item, idx)=><img key={idx} src={item.src} height="30" width={item.width || logoWidth} alt={"Customer logo "+idx} css={item.style ? css(item.style):null} />)}
            </div>
        </>
    )

}