
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
    image: {
        borderRadius: '260px',
        objectFit: 'cover',
    },
}));

export const Avatar = ({image}) => {

    const classes = useStyles();

    return (<img src={image} width="150" height="150" alt={'Avatar'} className={classes.image}/>);
}