import { Box, Grid } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { HighlightItem } from "./HighlightItem";

const useStyles = makeStyles((theme)=>({
    highlightGridItem: {
        '&+$highlightGridItem': {
            [theme.breakpoints.up('md')]: {
                //marginLeft: theme.spacing(4)
            },
            [theme.breakpoints.down('md')]: {
                marginTop: theme.spacing(3)
            },
        },
    },
}))

export const Highlights = ({items, justifyContent}) => {
    
    const classes = useStyles()

    justifyContent = justifyContent || "center"

    const renderHighlights = (items) => {
        return items.map((h,idx)=>{

            const { title } = h 

            return (
                    <Grid item key={idx} xs={12} md={4} className={classes.highlightGridItem} >
                        <HighlightItem justifyContent={justifyContent} title={title} />
                    </Grid>
                )
            }
        )
    }

    return (
        <Box pt={6} pb={6}>
            <Grid container justifyContent={justifyContent}>
                {renderHighlights(items)}
            </Grid>
        </Box>
    )

}