import { css } from "@emotion/react";
import { Box, Grid } from "@mui/material";
import { useIsMounted } from "modules/picasso-ui/form/useIsMounted";
import { SlideUpAnimation } from "modules/picasso-ui/present/effect/SlideUpAnimation";
import { TiltAnimation } from "modules/picasso-ui/present/effect/TiltAnimation";
import Image from "next/image";
import { FC, useState } from "react";
import { FormattedTypography } from "modules/picasso-ui/present/text/FormattedText";
import { MultilineTypography } from "modules/picasso-ui/present/MultilineText";
import { HeroProps } from "./HeroTypes";

export interface Hero1OldProps {
    mainMd?: number,
    hideSecondaryContentOnSmallScreen?: boolean
}

/**
 * linear-gradient(to bottom, #cfd6ff, #e1e7ff, #f8fbff, #ffffff 700px)
 * 
 * linear-gradient(to bottom, #cfd6ff, #e1e7ff, #f8fbff, #ffffff 1200px)
 * 
 * color: #3d486a
 * 
 * 
 * linear-gradient(to bottom, #edf3ff, #fdfeff, #f8fbff, #ffffff 1200px)
 * 
 * 
 * @param {*} mainTitle  - deprecated 
 * @param {*} mainSubtitle   - deprecated 
 * @returns 
 */
//mainIncentiveLink => deprecated, use content instead
export const Hero1: FC<HeroProps & Hero1OldProps> = ({title, titleVariant = 'h1', subtitle, primaryContent, secondaryContent, minHeight, gap, titleStyle, mainMd, startContent, animationEnabled, hideSecondaryContentOnSmallScreen}) => {

    gap = gap??0

    const renderPrimaryInner = () => {

        const primaryContentCss = (theme)=>({
            paddingTop: theme.spacing(1),
            //paddingRight: theme.spacing(2),
        })

        return (
            <div css={primaryContentCss}>
                {/** @ts-ignore */}
                <FormattedTypography
                    variant={titleVariant} 
                    sx={[
                        (theme)=>({
                            color: theme.palette.text.primary,
                            textAlign: 'left',
                            fontWeight: '700',
                            lineHeight: '1.35',
                        }),
                        titleVariant==='h1'?{fontSize: '2.6em',}:null,
                        titleVariant==='h2'?{fontSize: '2.3em',}:null,
                        titleStyle
                    ]}
                >
                    {title}
                </FormattedTypography>
        
                {/** @ts-ignore */}
              {subtitle && <MultilineTypography 
                    component="div" 
                    sx={(theme)=>({
                        marginTop: theme.spacing(2),
                        color: theme.palette.text.secondary,
                        fontWeight: '400',
                        fontSize: '17px',
                        textAlign: 'left',
                        lineHeight: '28.5px',
                        paddingRight: theme.spacing(4),
                        maxWidth: '100%',
                        width: '450px',
                    })}
                    ignoreOnXs={true}>
                    {subtitle}
                </MultilineTypography>
              }
            {primaryContent && <Box mt={4}>{primaryContent}</Box>}
            </div>
          )
    } 

    mainMd = mainMd || 6

    const gridCss = (theme)=>({
        [theme.breakpoints.down('md')]: {
            //flexDirection: 'column-reverse',
            alignItems: 'flex-start',
            width: '100% !important',
            margin: '0',
            '& >*': {
                paddingTop: '0px !important',
                paddingLeft: '0px !important',
                paddingRight: '0px !important',
            } 
        },
        [theme.breakpoints.up('md')]: {
            alignItems: 'flex-start',
        }
    })

    const secondaryContentCss = [
        (theme)=>({
            textAlign:'right',
            [theme.breakpoints.down('md')]: {
                marginTop: theme.spacing(4)
            }
        }),
        hideSecondaryContentOnSmallScreen ? (theme)=>({
            [theme.breakpoints.down('md')]: {
                textAlign:'center',
                width: '100% !important',
                display: 'none',
            }
        }):null,
    ]

    const animationContainerCss = (theme)=>css({
        textAlign:'right',
        [theme.breakpoints.down('md')]: {
            textAlign:'center',
            width: '100% !important',
        },
        '&>*': {
           marginLeft: 'auto',
        }
    })

    return (
            <Box
                sx={(theme)=>({
                    textAlign: 'left',
                    paddingTop: '7vh',
                    paddingBottom: '11vh',
                    minHeight: minHeight??'72vh',
                    [theme.breakpoints.down('md')]: {
                        marginBottom: theme.spacing(4), // when row layout, the margin is always enough, but not in xs
                        paddingTop: '5vh',
                        minHeight: 0,
                    },
                })}
            >

                {startContent}

                <Grid 
                    container 
                    spacing={gap} 
                    justifyContent="space-between" 
                    sx={gridCss}
                >

                    <Grid item xs={12} md={mainMd}>
                        {animationEnabled 
                            //@ts-ignore
                            ? <SlideUpAnimation><div>{renderPrimaryInner()}</div></SlideUpAnimation>
                            : renderPrimaryInner()
                        }
                    </Grid>

                    <Grid item xs={12} md={12-mainMd} sx={secondaryContentCss}>
                        {secondaryContent && (
                            animationEnabled 
                                ? <TiltAnimation><div css={animationContainerCss}>{secondaryContent}</div></TiltAnimation>
                                : secondaryContent
                        )}
                    </Grid>
                </Grid>
        </Box>
    );

}

const FramedResponsiveImage = ({src, width, height}) => {

    const [loaded, setLoaded] = useState(false)

    const isMounted = useIsMounted()

    const onLoadingComplete = () => {
        if (isMounted()) {
            setLoaded(true)
        }
    }

    const backgroundColor = '#f3f3f3' //loaded ? null : '#f3f3f3'
    const opacity = loaded ? 1 : 0

    return (
        <>
        <TiltAnimation> {/** important: must wrap the box because the animation should start when box is rerendered */}
            <Box
            style={{
                borderRadius: '25px',
                //border: '1px solid red',
                overflow: 'hidden',
                boxShadow: 'rgb(100 100 111 / 20%) 0px 7px 29px 0px',
                position: 'relative',
                //height: '50vh',
                border: '1px solid #f3f3f3',
                maxHeight: '50vh',
                opacity: opacity,
                backgroundColor: backgroundColor,
            }}
            mb={15}
            className="effect-shine"
            >
                <Image src={src} 
                    layout="responsive" 
                    //objectFit="cover"
                    width={width} 
                    height={height} 
                    sizes="50vw"
                    quality={100}
                    onLoadingComplete={onLoadingComplete}
                    priority={true}                    
                    />
            </Box>
        </TiltAnimation>
       </>
    )

}