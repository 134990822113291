import { css } from "@emotion/react";
import { Box, lighten } from "@mui/material";
import { Fragment, FunctionComponent, useId, useRef } from "react";
import { toKebabCase } from "utils/stringUtils";

export interface LandingContentProps {
    title?: string
    items?: LandinContentItem[]
}

export interface LandinContentItem {
    title: string
    content?: string | any[]
}

const styleDef = {
    root: ()=>theme=>(css({
        position: 'relative',
        '& input': {
            display: 'none',
        },
        '& label': {
            color: theme.palette.text.secondary,
            cursor: 'pointer',
            fontSize: '1em',
            fontWeight: 400,
            display: 'inline-block',
            userSelect: 'none',
            flex: '1 1 0px',
        },
        '& label .title': {
            display: 'inline-block',
            whiteSpace: 'nowrap',
        },
        [theme.breakpoints.down('sm')]: {
            '& label': {
                display: 'block',
            },
        },
        [theme.breakpoints.up('sm')]: {
            '& label ~ label': {
                marginLeft: '8px',
            },
        },
        '& .lpTabs-ct': {
            display: 'none',
            marginTop: '18px',
        },
        '& .lpTabs-tab0:checked ~ .lpTabs-ct.lpTabs-ct0': {
            display: 'block',
        },
        '& .lpTabs-tab1:checked ~ .lpTabs-ct.lpTabs-ct1': {
            display: 'block',
        },
        '& .lpTabs-tab2:checked ~ .lpTabs-ct.lpTabs-ct2': {
            display: 'block',
        },
        '& .lpTabs-tab3:checked ~ .lpTabs-ct.lpTabs-ct3': {
            display: 'block',
        },
        '& .lpTabs-tab4:checked ~ .lpTabs-ct.lpTabs-ct4': {
            display: 'block',
        },
        '& .lpTabs-tab5:checked ~ .lpTabs-ct.lpTabs-ct5': {
            display: 'block',
        },
    })),
}

const toggleVariantStyle = {
    'standard': {
        '& label': {
            fontSize: '16px',
            padding: '0px 14px',
            borderRadius: '4px',
            '&:hover': {
                background: 'rgb(157 173 193 / 10%)',
            }
        },
        '& label .title': {
            display: 'inline-block',
            padding: '11px 0px 11px 0px',
        },
        '& input:checked+label .title': {
            borderBottom: `3px solid rgba(20 32 58 / 79%)`, //`3px solid ${theme.palette.text.primary}`,
            padding: '11px 0px 8px 0px',
        },
    },
    'underline': theme=>css({
        '& label': {
            fontSize: '14px',
            borderRadius: '4px',
            display: 'inline',
            position: 'relative',
            overflow: 'hidden',
            zIndex: '2',
            '&::after': {
                content: '""',
                position: 'absolute',
                zIndex: '1',
                left: '0',
                right: '100%',
                bottom: '-5px',
                background: theme.palette.text.primary,
                height: '1px',
                transitionProperty: 'left right',
                transitionDuration: '0.3s',
                transitionTimingFunction: 'ease-out',
            },
            '&:hover::after': {
                right: 0,
            }
        },
        '& label .title': {
            display: 'inline-block',
        },
        '& input:checked+label::after': {
            right: 0,
        },
    }),
    'smooth': {
        '& label': {
            padding: '0px 0px',
            fontWeight: 400,
            color: '#7f87a0',
            fontSize: '0.9em',
            borderRadius: '99px',
            '&:hover .title': {
                background: 'rgb(157 173 193 / 10%)',
            }
        },
        '& label .title': {
            padding: '6px 16px',
            borderRadius: '99px',
        },
        '& input:checked+label, & label:active, & label:focus': {
/*             background: '#fff',
            boxShadow: '0 0.1875rem 0.375rem rgb(140 152 164 / 25%)', */
        },
        '& input:checked+label .title': {

        },
        '& .lpTabs-tab0:checked ~ div label:nth-of-type(1) .title': {
            background: 'rgb(157 173 193 / 10%)',
        },
        '& .lpTabs-tab1:checked ~ div label:nth-of-type(2) .title': {
            background: 'rgb(157 173 193 / 10%)',
        },
        '& .lpTabs-tab2:checked ~ div label:nth-of-type(3) .title': {
            background: 'rgb(157 173 193 / 10%)',
        },
        '& .lpTabs-tab3:checked ~ div label:nth-of-type(4) .title': {
            background: 'rgb(157 173 193 / 10%)',
        },
        '& .lpTabs-tab4:checked ~ div label:nth-of-type(5) .title': {
            background: 'rgb(157 173 193 / 10%)',
        },
    },
    'smooth2': theme=>css({
        '& label': {
            color: theme.palette.text.secondary,
            fontSize: '14px',
            padding: '1px 18px',
            borderRadius: '99px',
            '&:hover': {
                background: 'rgb(157 173 193 / 10%)',
            }
        },
        '& label .title': {
            padding: '6px 0',
        },
        '& input:checked+label': {
            background: 'rgb(157 173 193 / 10%)',
        },
    }),
    'smooth3': theme=>css({
        '& label': {
            color: theme.palette.text.secondary,
            padding: '1px 18px',
            background: '#fff',
            boxShadow: '0 2px 3px 0 rgba(12, 0, 46, 0.06)',
            borderRadius: '4px',
            border: `2px solid #fff`,
            transition: 'all 0.2s linear',
        },
        '& label .title': {
            padding: '6px 0',
        },
        '& label:hover': {
            border: `2px solid ${lighten(theme.palette.primary.main,0.6)}`,
        },
        '& input:checked+label,& label:hover': {
            //background: theme.palette.primary.main,
            //color: '#fff',
            //boxShadow: 'none',
            //borderRadius: '4px'
        },
    })
}

export const LandingTabs: FunctionComponent<LandingContentProps & {toggleVariant?: 'standard'|'underline'|'smooth'|'smooth2'|'smooth3', togglePosition?: 'top'|'bottom'}> = ({items, toggleVariant, togglePosition}) => {

    const radioId = useId()

    const radioName = useRef('landtabs-r-'+radioId)
    
    const getInputId = (i) => {
        return 'landtabs-id-'+toKebabCase(i.title)
    }

    togglePosition = togglePosition || 'top'
    toggleVariant = toggleVariant || 'smooth'

    const renderToggleBar = (mt, mb) => {
        return <Box display="flex" 
                justifyContent="center" 
                border="0px solid #eff2f5" borderRadius="99px" padding="3px" mt={mt} mb={mb} maxWidth={(items?.length||0)*165} marginLeft="auto" marginRight="auto" textAlign="center"
                flexWrap="wrap"
                sx={theme=>({[theme.breakpoints.down('sm')]:{ flexDirection: 'column' }})}
                >
            {items.map((i, idx)=>{
                return <Fragment key={idx}><label htmlFor={getInputId(i)}><span className="title">{i.title}</span></label></Fragment>
            })}
        </Box>
    }

    return (
        <div css={{position: 'relative'}}>
{/*             {toggleVariant === 'smooth' && 
                <div css={css({
                    position: 'absolute', 
                    left: '50%', 
                    width: `calc(${items.length||0} * 172px)`, 
                    borderRadius: '99px', 
                    //background: '#eff2f5', 
                    border: '2px solid #eff2f5',
                    height: '45px', 
                    transform: 'translateX(-50%)', 
                    maxWidth: '100%'
                })}></div>
            } */}
            <div css={[styleDef.root, toggleVariantStyle[toggleVariant]||null]}>
                {items && (<>
                    {items.map((i, idx)=>{
                        return <Fragment key={idx}><input name={radioName.current} type="radio" id={getInputId(i)} defaultChecked={idx===0} className={`lpTabs-tab${idx}`} /></Fragment>
                    })}
                    {togglePosition === 'top' && renderToggleBar(0,0)}
                    {items.map((i, idx)=>{
                        return <div key={idx} className={`lpTabs-ct lpTabs-ct${idx}`}>{i.content}</div>
                    })}
                     {togglePosition === 'bottom' && renderToggleBar(2, 10)}
                </>)}
            </div>
        </div>
    )

}