import { useEffect, useRef, useState } from "react";

const easeOutQuad = t => t * ( 2 - t );
const frameDuration = 1000 / 60;

export const CountUpAnimation = ({ children, duration = 2000}) => {
	const countTo = parseInt( children, 10 )
	const [ count, setCount ] = useState( 0 )

    const prev = useRef()

	useEffect(() => {
		let frame = 0;

        const totalFrames = Math.round( duration / frameDuration )

        let countFrom = prev.current || 0
        let diff = 0

        if (prev !== countTo) {
            diff = countTo-countFrom
        }
		
		const counter = setInterval( () => {
			frame++;
			const progress = easeOutQuad( frame / totalFrames )
			setCount( countFrom + (diff * progress) )

			if ( frame === totalFrames ) {
				clearInterval( counter )
			}
		}, frameDuration )

        prev.current = countTo

	}, [children])

	return Math.floor( count )
}