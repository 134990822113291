import { Grid, Box } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

const useStyles = makeStyles((theme) => ({
    noticeBar: {
        textAlign: 'center',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            textAlign: 'left',
            marginBottom: theme.spacing(0),
        }
    },
    socialBar: {
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            textAlign: 'right'
        }
    },
    socialLink: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
       '&:last-child': {
         marginRight: '0',
       },
    },
  }));

export const LandingFooterNotice = ({noticeText, socialLinks}) => {

    const classes = useStyles();

    const renderSocialLinks = () => {
        if (!socialLinks) {
            return <></>;
        }
        return socialLinks.map((l, idx) => {
            var icon = <></>;
            if (l.startsWith('https://www.instagram.com')) {
                icon = <InstagramIcon fontSize="large" />;
            } 
            else if (l.startsWith('https://www.youtube.com')) {
                icon = <YouTubeIcon fontSize="large" />;
            }
            else if (l.startsWith('https://twitter.com')) {
                icon = <TwitterIcon fontSize="large" />;
            }
            else if (l.startsWith('https://www.facebook.com')) {
                icon = <FacebookIcon fontSize="large" />;
            }
            return <a href={l} target="_black" key={idx} className={classes.socialLink}>{icon}</a>
        });
    }

    return (
        <Box display="flex" alignItems="center" pt={2} pb={2}>
            <Grid container alignItems="center">
                <Grid item md={6} xs={12}>
                    <Box color="text.secondary" className={classes.noticeBar}>
                        {noticeText}
                    </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Box className={classes.socialBar}>
                        {renderSocialLinks()}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}