import { useState } from "react";
import { InView } from "react-intersection-observer";

export const AnimateWhenInViewport = ({children, offset}) => {

    const [animate, setAnimate] = useState(false)

    const handleWithDelay = (inView) => {
        if (inView === true) {
            setTimeout(()=>setAnimate(true), 150)
        }
    }

    return (
        <InView as="div" triggerOnce={true} onChange={handleWithDelay} initialInView={false} rootMargin={`0px 0px -${offset||'100px'} 0px`}>
            {children(animate)}
        </InView>
    )

}