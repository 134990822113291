export const createBgShapeStyle = (bgShape) => {

    return {
        position: 'relative',
        '&::before': {
            content: '""',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            width: '100%',
            height: '100%',
            ...bgShape
        }
    }

}

export const bgShapeSimpleStyle = () => {

    return createBgShapeStyle({
        borderRadius: '30% 70% 70% 30% / 30% 30% 70% 70%',
    })

}