import { Box, css, Typography } from "@mui/material"
import { FormattedTypography } from "modules/picasso-ui/present/text/FormattedText"
import Image from "next/image"
import { FunctionComponent } from "react"
import { Testimonial } from "./TestimonialTypes"

export interface LandingCustomerVoiceLargeProps {
    items: Testimonial[]
    style?: any
    textsWrapperStyle?: any
    textStyle?: any
    avatarStyle?: any
    avatarSpacing?: 'standard' | 'fill'
    paranthesis?: boolean
}

const styleDef = {
    item: theme=>css({
        padding: '24px 0',
        display: 'flex',
        justifyContent: 'space-between',
        gap: '24px',
        '&+&': {
            marginTop: '56px'
        },
/*         '&+&': {
            marginTop: '48px'
        }, */
        '& .lcv-textsWrapper': {
            maxWidth: '65%'
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '0.8em',
/*             '& .lcv-textsWrapper': {
                maxWidth: theme.breakpoints.values.md-200
            } */
        },
        [theme.breakpoints.down('sm')]: {
            '& .lcv-textsWrapper': {
                maxWidth: '100%'
            }
        }
    }),
    roleStyle: css({
        fontWeight: '400'
    }),
    avatarWarpper: theme=>({
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    })
}



export const LandingCustomerVoiceLarge: FunctionComponent<LandingCustomerVoiceLargeProps> = ({style, items, textsWrapperStyle, textStyle, avatarStyle = {}, avatarSpacing, paranthesis}) => {

    if (!items) {
        return null;
    }

    const { width = "250", height = "250", objectFit, ...imgStyle } = avatarStyle

    return <>
        {items.map((item, idx)=>
            <div key={idx} css={[styleDef.item, style]}>
                <Box display="flex" alignItems="center" maxWidth="550px" sx={textsWrapperStyle} className="lcv-textsWrapper">
                    <div>
                        {/** @ts-ignore */}
                        <FormattedTypography fontSize="1.6em" mb={6} component="blockquote" p={0} lineHeight="1.85em" sx={textStyle}>{paranthesis?'“'+item.text+'”':item.text}</FormattedTypography>
                        <Typography fontSize="1em" fontWeight="400">{item.name}<span css={styleDef.roleStyle}>{item.name && item.role? ', ':''}{item.role}</span></Typography>
                        {item.organization && <Typography color="#aeb2b6" fontSize="0.9em" fontWeight="400" mt={1} pb="20px">{item.organization}</Typography>}
                    </div>
                </Box>
                {item.avatar && <Box position="relative" sx={[styleDef.avatarWarpper, {minHeight: 0, height: '100%', width}, imgStyle]}><Image src={item.avatar} width={width} height={height} loading="lazy" alt="Avatar of user" layout={avatarSpacing==='fill'?'fill':undefined} objectFit={objectFit} /></Box>}
            </div>
        )}
    </>

}