import { Typography, Box } from '@mui/material';
import Link from 'next/link';
import { isString } from 'utils/stringUtils';

export const LogoSmall = ({iconSrc, text, textSrc, logoWithTextWidth, logoWithTextHeight, watermark, textColor, iconOnly, target, width, height, ariaLabel, alt, noLink, href, textImgStyle, capitalize}) => {

  const showText = iconOnly !== true && (text || textSrc)

  const imageCss = [
    {
      objectFit: 'contain',
      objectPosition: 'left center',
    }
  ]
  if (watermark === true) {
    imageCss.push(
      {
        filter: 'grayscale(70%) brightness(1.2)',
      }
    )
  }

  const textCss = [{
      //fontFamily: 'Arial, sans-serif',
      fontWeight: 500,
      fontSize: '19px',//'23px',
      display: 'block',
      marginLeft: '10px',
      whiteSpace: 'nowrap',
      textTransform: capitalize?'capitalize':null,
  }]
  if (textColor) {
    textCss[0].color = textColor
  }
  else if (watermark === true) {
    textCss[0].color = '#1f5cc3'
  } else {
    textCss.push((theme)=>({
      color: theme.palette.text.primary,
    }))
  }
  width = width || 25
  height = height || 25

  const renderInner = () => {
    return (
      <Box display="inline-flex" alignItems="center" justifyContent="center">
        <Box display="flex" alignItems="center">
          {(showText || (iconOnly && text)) ? 
            <Box display="flex" alignItems="center" style={{marginTop: iconOnly ? 0:0}}>
              <img src={iconSrc} height={height} width={width} css={imageCss} alt={alt} />
            </Box>
            :
            <Box display="flex" alignItems="center" style={{marginTop: iconOnly ? 0:0}} width={width || "250px"} height="40px" position="relative">
              <img src={iconSrc} height={height} width={width} css={imageCss} alt={alt} />
            </Box>
          }
          {isString(text) ? 
            <>
              {showText && (
                <Box display="flex" alignItems="center">
                  <Typography component="span" align="center" sx={textCss}>{text}</Typography>
                </Box>
              )}
            </>
            :
            <>
              {showText && 
                 <img src={textSrc} height={logoWithTextHeight || '15px'} width={logoWithTextWidth || '100px'} alt={alt} css={[{marginLeft: '11px'},textImgStyle]} />
              }
            </>
          }
        </Box>
      </Box>
    )
  }

  return (
    <>
      {noLink === true ? 
        renderInner()
        :
        <Link href={href||'/'}>
          <a href={href||'/'} 
            css={{
              textDecoration: 'none',
              display: 'flex',
              alignItems: 'center',
            }} 
            target={target} 
            aria-label={ariaLabel}
            >
            {renderInner()}
          </a>
        </Link>
      }
    </>
  )
}