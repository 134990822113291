
import { Grid, Typography, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { LandingImage } from 'components/layout/partials/LandingImage';
import { TypoLandingHeading } from 'modules/landingPage/TypographyLanding';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export const LandingPageValuePropositions = ({title, titleColor, itemsColor, image, items}) => {

    const classes = useStyles();

    const renderItems = () => {
        return items.map((i, idx)=>{
            return (<Box key={idx} display="flex">
                <Box display="flex">
                    <span className={[itemsColor === 'contrastText' ? classes.contrastTextPrimary : ''].join(' ')} style={{marginRight: '12px'}}>
                        <CheckCircleOutlineIcon />
                    </span>
                </Box>
                <Box mb={5}>
                    <Box mb={1}>
                        <TypoLandingHeading size="5" variant="h2" color={itemsColor}>
                        <span className={[itemsColor === 'contrastText' ? classes.contrastTextPrimary : '', classes.itemTitle].join(' ')}>
                            {i.title}
                        </span>
                        </TypoLandingHeading>
                    </Box>
                    <Typography>
                        <span className={[itemsColor === 'contrastText' ? classes.contrastTextPrimary : '', classes.itemText].join(' ')}>
                            {i.text}
                        </span>
                    </Typography>
                </Box>
            </Box>);
        });
    }

    return (
        <Box pt={3} pb={10} className={classes.root}>  
            <Grid container alignItems="center">
                <Grid item md={6} xs={12}>
                    <LandingImage src={image} width="335" height="667"/>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Box textAlign="left">
                        <Box mb={4}>
                            <TypoLandingHeading size="3" variant="h2" color={titleColor}>
                                <span className={[titleColor === 'contrastText' ? classes.contrastTextPrimary : '', classes.heading].join(' ')}>{title}</span>
                            </TypoLandingHeading>
                        </Box>
                        <div className={[titleColor === 'contrastText' ? classes.contrastTextPrimary : '', classes.itemText].join(' ')}>
                            {renderItems()}
                        </div>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );

}

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down('md')]: {
            paddingBottom: '200px'
        },
    },
    heading: {
      whiteSpace: 'pre-line',
      display: 'inline-block'
    },
    subtitle: {
    },
    itemTitle: {
/*         [theme.breakpoints.down('sm')]: {
            fontSize: '12px'
        }, */
    },
    itemText: {
        [theme.breakpoints.down('md')]: {
            fontSize: '12px'
        },
    },
    contrastTextPrimary: {
      color: theme.palette.primary.contrastText
    },
  }));