import { Avatar, Box, Typography } from "@mui/material";
import { ResponsiveFlexBox } from "modules/picasso-ui/dataviews/grid/ResponsiveFlexBox";
import { SimpleCard } from "modules/picasso-ui/present/dashboard/SimpleCard"
import { FunctionComponent } from "react";
import { Testimonial } from "./TestimonialTypes";
import { FormattedTypography } from "modules/picasso-ui/present/text/FormattedText";
import { useTranslation } from "next-i18next";
import { RatingStars } from "modules/picasso-ui/present/RatingStars";

const styleDef = {
    text: theme=>({
        fontSize: '22px',
        color: theme.palette.text.secondary,
        fontWeight: '400',
        lineHeight: '32px',
    }),
    name: theme=>({
        fontSize: '12px',
        color: theme.palette.text.secondary,
        fontWeight: '500',
    }),
    org: theme=>({
        fontSize: '12px',
        color: theme.palette.text.secondary,
    }),
}

export interface LandingCustomerVoicesCardsProps {
    items: Testimonial[]
    showQuotesIcon?: boolean,
    showAvatar?: boolean,
    showRating?: boolean
    textAlign?: 'left' | 'center' | 'right'
    avatarPosition?: 'top' | 'center'
    cols?: number
    flexDirectionColumnDown?: string
    textStyle?: any
    nameStyle?: any
    roleStyle?: any
    cardDesignVariant?: 'frameless'|'dashboard'|'outlined'
}

export const LandingCustomerVoicesCards: FunctionComponent<LandingCustomerVoicesCardsProps> = ({items, showQuotesIcon = true, showRating, showAvatar, avatarPosition, textAlign, cols, flexDirectionColumnDown, textStyle, nameStyle, roleStyle, cardDesignVariant}) => {

    const { t } = useTranslation('common')

    textAlign = textAlign || 'center'

    return (
        <Box pt={8} pb={4}>
            {/** @ts-ignore */}
            <ResponsiveFlexBox itemsCount={items?.length || 0} cols={cols||2} flexDirectionColumnDown={flexDirectionColumnDown}>
                {items?.filter(i=>i.visible!=false).map((i,idx)=>{
                    //@ts-ignore
                    return <SimpleCard key={idx} designVariant={cardDesignVariant}>
                            <Box flexGrow={1} textAlign={textAlign} p={1}>
                                {showQuotesIcon && <img src="/picasso-ui/quote.svg" width="50" height="35" loading="lazy" alt="Quote sign" />}
                                {showAvatar && avatarPosition === 'center' &&
                                    <Box mr={1}>
                                        <Avatar src={i.imgSrc} alt={`Avatar of ${i.name}`} imgProps={{loading: 'lazy', width: '40', height: '40'}}></Avatar>
                                    </Box>
                                }
                                {/** @ts-ignore */}
                                <FormattedTypography sx={[styleDef.text, textStyle]} mt={2}>{'“'+i.text+'”'}</FormattedTypography>
                                <Box mt={3}>
                                    <Box display="flex" alignItems="center" justifyContent={textAlign === 'left'?'flex-start':'center'}>
                                        {showAvatar && avatarPosition === 'center' &&
                                            <Box mr={1}>
                                                <Avatar src={i.imgSrc} alt={`Avatar of ${i.name}`} imgProps={{loading: 'lazy', width: '40', height: '40'}}></Avatar>
                                            </Box>
                                        }
                                    </Box>
                                </Box>
                                {showRating && <Box mt={0}><RatingStars /></Box>}
                                <Box >
                                        <Typography sx={[styleDef.name, nameStyle]} mb={0.5} textAlign={textAlign}>{textAlign === 'left' ? <>&#8212;</>:null} {i.name}</Typography>
                                        <Typography sx={[styleDef.org, roleStyle]} textAlign={textAlign}>{i.role}{i.organization ? ' '+(i.role?(t('common:socialProof.detail.at')+ ' '):'') +i.organization : null}</Typography>
                                </Box>
                            </Box>
                        </SimpleCard>
                })}
            </ResponsiveFlexBox>
        </Box>
    )

}