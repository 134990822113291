import { Box, Button } from "@mui/material";
import { css } from "@emotion/react";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { orElse } from "utils/objectUtils";
import { FormattedText, FormattedTypography } from "modules/picasso-ui/present/text/FormattedText";
import { ElementLink } from "modules/picasso-ui/link/LinkNormal";
import { IconRenderer } from "modules/picasso-ui/specials/icons/IconRenderer";

const stylesDef = {
    root: {
        whiteSpace: 'nowrap',
    },
    rootFixed: {
        position: 'fixed',
        bottom: '0px',
        right: '0px',
    },
    suffix: css({
        marginLeft: '10px',
        color: 'rgb(255,255,255,0.44)',
        fontWeight: '400',
    }),
}

//children is preferred over title
export const MainIncentiveLink = ({title, href, onClick, secondaryText, buttonProps, fullWidth, showArrow, children, suffix, target, inIframe, fixed, size, boxShadow, fullWidthDown, style, endIcon, secondaryTextStyle}) => {

    const rootStyle = [stylesDef.root, fixed?stylesDef.rootFixed:null]

    fullWidth = orElse(fullWidth, true)

    showArrow = orElse(showArrow, false)

    const contentEffective = title || children

    buttonProps = {...buttonProps} || {}
    if (!buttonProps.color) {
        buttonProps.color = 'primary'
    }
    if (!buttonProps.variant) {
        buttonProps.variant = 'contained'
    }
    size = size || 'extraLarge'

    const buttonStyle = [{fontWeight: 600},buttonProps.sx, buttonProps.style, fullWidthDown?theme=>({[theme.breakpoints.down(fullWidthDown)]: { width: '100%', maxWidth: '100%' }}):null]

    if (boxShadow) {
        buttonStyle.push({'&,&:hover': {boxShadow: '0 20px 24px 0 rgb(1 20 71 / 10%)'}})
    }

    return (
        <>
            <Box sx={rootStyle}>
                {href ? 
                    <ElementLink href={href} target={target} inIframe={inIframe} prefetch={false}>
                        <Button
                            fullWidth={fullWidth} 
                            {...buttonProps}
                            style={undefined}
                            size={size}
                            hoverInvert={buttonProps.variant==='contained'}
                            endIcon={endIcon? <IconRenderer name={endIcon} />:undefined}
                            sx={buttonStyle}
                            >
                            {contentEffective}
                            {showArrow &&  <>&nbsp;&nbsp;<ArrowRightAltIcon /></>}
                            {suffix && <span css={stylesDef.suffix}>{suffix}</span>}
                        </Button>
                    </ElementLink>
                    :
                    <Button 
                        fullWidth={fullWidth} 
                        onClick={onClick}
                        {...buttonProps}
                        size={size}
                        hoverInvert={buttonProps.variant==='contained'}
                        sx={buttonStyle}
                        >
                        {contentEffective}
                        {showArrow &&  <>&nbsp;&nbsp;<ArrowRightAltIcon /></>}
                        {suffix && <span css={stylesDef.suffix}>{suffix}</span>}
                    </Button>
                }

            </Box>
            {secondaryText && (
                <FormattedTypography color="text.secondary" component="div" sx={{fontWeight: 400, lineHeight: '25px', mt: 3, ...secondaryTextStyle}}>{secondaryText}</FormattedTypography>
            )}
        </>
    )

}