import { useTranslation } from "next-i18next"
import { isHavingValue, notNullNotUndefined } from "utils/objectUtils";
import { capitalizeFirstLetter } from "utils/stringUtils";

export const usePricingPlansDto = ({namespace, plans, features, options}) => {
    notNullNotUndefined(namespace)
    notNullNotUndefined(plans)
    notNullNotUndefined(features)
    notNullNotUndefined(options)

    const { t } = useTranslation(namespace)

    const getFeatureDef = (features, featureKey) => {
        notNullNotUndefined(features)
        notNullNotUndefined(featureKey)
        const def = features.find(f=>f.key === featureKey)
        if (!def) {
            throw new Error('feature not in app ' + featureKey)
        }
        return def
    }

    const convertToFeatureDisplayed = (planDef, featureDef, settings) => {
        notNullNotUndefined(planDef)
        notNullNotUndefined(featureDef)
        notNullNotUndefined(settings)

        if (!planDef.features.includes(featureDef.key)) {
            throw new Error('feature not in plan ' + featureDef.key + ' (' + planDef.name+')')
        }

        const featureAsDisplayed = {}

        if (featureDef.comingSoon === true) {
            featureAsDisplayed.comingSoon = true
        }
        if (featureDef.closedBeta === true) {
            featureAsDisplayed.closedBeta = true
        }

        const featureQuotaDisplayed = getFeatureQuotaDisplayed(planDef, settings, featureDef.key)
        if (isHavingValue(featureQuotaDisplayed)) {
            featureAsDisplayed.quota = featureQuotaDisplayed
            if (featureQuotaDisplayed === 'Unlimited') {
                featureAsDisplayed.quotaUnlimited = true

                const whenUnlimitedTitle = t(`${namespace}:app.features.${featureDef.key}.whenUnlimitedTitle`)
                if (whenUnlimitedTitle && !whenUnlimitedTitle.includes(`app.features.${featureDef.key}.whenUnlimitedTitle`)) {
                    featureAsDisplayed.quotaUnlimitedTitle = whenUnlimitedTitle
                }
            }
        }

        const titleParams = featureAsDisplayed.quota
            ? {quota: featureAsDisplayed.quota}
            : null

        featureAsDisplayed.title = t(`${namespace}:app.features.${featureDef.key}.title`, titleParams)
        featureAsDisplayed.info = t(`${namespace}:app.features.${featureDef.key}.helperText`)

        if (titleParams && featureAsDisplayed.title) {
            featureAsDisplayed.title = capitalizeFirstLetter(featureAsDisplayed.title)
        }

        if (featureAsDisplayed?.info.includes(`app.features.${featureDef.key}.helperText`)) {//no translation = dont show helpertext
            featureAsDisplayed.info = null
        }

        return featureAsDisplayed;
    }

    /**
     * 
     * @param {*} planDef 
     * @param {*} settings 
     * @returns 
     *    number or string or null
     */
    const getFeatureQuotaDisplayed = (planDef, settings, f) => {
        notNullNotUndefined(planDef)
        notNullNotUndefined(settings)
        notNullNotUndefined(f)

        if (planDef.quotas) {
            const quota = planDef.quotas[f]
            if (isHavingValue(quota)) {
                if (quota === -99) {
                    return "Unlimited"
                } else {
                    return quota
                }
            }
        } else if (settings.quotas?.[f]){
            const quota = settings.quotas?.[f]
            if (quota === -99) {
                return "Unlimited"
            } else {
                return quota
            }
        }

        return null
    }

    const getDto = () => {
    
        const { currencies, items } = options
    
        const componentProps = {
            items: []
        }
    
        items.forEach(({key, settings})=>{
            
            const planDef = plans.find(p=>p.key===key)
    
            if (!planDef) {
                console.error('key: '+key)
            }
            notNullNotUndefined(planDef)

            const { buttonHref } = settings
    
    
            const planAsDisplayed = {
                featuresMain: [],
                features: [],
                key,
            }
            planAsDisplayed.title = planDef.name
            if (planDef.free === true) {
                planAsDisplayed.free = true
            }
            //copy prices of currencies
            if (planDef.price) {
                planAsDisplayed.price = {}
                currencies.forEach((c)=>{
                    planAsDisplayed.price[c] = planDef.price[c]
                })
            } else if (planDef.free === true) {
                planAsDisplayed.free = true
            } else if (planDef.custom === true) {
                planAsDisplayed.custom = true
            }
            if (buttonHref) {
                planAsDisplayed.buttonHref = buttonHref
            }
    
            
            //showIncludesPlanText
            if (settings.showIncludesPlanText) {
                const titleOfIncludedPlan = plans.find(p=>p.key===settings.showIncludesPlanText).name
                notNullNotUndefined(titleOfIncludedPlan)
                planAsDisplayed.includesText = "Everything in <b700>" + titleOfIncludedPlan + "</b700> and:"
            } 
            else {
                planAsDisplayed.includesText = `Your <b700>free ${planDef.name}</b700> features:`
            }
    
            //buttonText
            if (settings.buttonText) {
                planAsDisplayed.buttonText = settings.buttonText
            }

            if (settings.showFeaturesMain) {
                planAsDisplayed.featuresMain = settings.showFeaturesMain.map(f=>convertToFeatureDisplayed(planDef, getFeatureDef(features, f), settings))
            }
    
            if (settings.showFeaturesList) {
                planAsDisplayed.features = settings.showFeaturesList.map(f=>convertToFeatureDisplayed(planDef, getFeatureDef(features, f), settings))
            }

            planAsDisplayed.tagline = planDef.tagline
            planAsDisplayed.variants = planDef.variants?.map(v=>{

                var priceMonthly = {}
                Object.keys(v.priceMonthly).forEach(function(key) {
                    if (options.currencies.includes(key)) {
                        priceMonthly[key] = v.priceMonthly[key];
                    }
                });
                var priceTotal = {}
                Object.keys(v.priceTotal).forEach(function(key) {
                    if (options.currencies.includes(key)) {
                        priceTotal[key] = v.priceTotal[key];
                    }
                });

                return { ...v, 
                    priceMonthly,
                    priceTotal,
                }
            })
/* 
            planAsDisplayed.variants = planDef.variants.map((v)=>{

            })
            currencies.forEach((c)=>{
                planAsDisplayed.price[c] = planDef.price[c]
            }) */

            componentProps.items.push(planAsDisplayed)
        })
        

        return componentProps
    }

    return { getDto }

}