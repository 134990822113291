import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { CopyLinkButton } from "./CopyLinkButton";
import { Box, IconButton, MenuItem, Select, Tooltip, Typography } from "@mui/material";
import CachedIcon from '@mui/icons-material/Cached';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const styleDef = {
    window: css({
        maxWidth: '800px',
        margin: 'auto',
        //background: 'rgb(120 73 194)',
        background: '#fff',
        filter: 'drop-shadow(rgba(0, 0, 0, 0.1) 0mm 5mm 3mm)',
        borderRadius: '14px',
        paddingBottom: '0px',
        border: '1px solid #cbcbdb',
        overflow: 'hidden',
    }),
    title: theme=>css({
        background: 'rgb(189 194 202)',//theme.palette.primary.light,
        padding: '4px 0px',
        marginBottom: '10px',
        color: '#fff',
        fontWeight: '600',
        fontSize: '12px'
    }),
    text: theme=>css({
        color: theme.palette.text.secondary,
        width: '100%',
        border: 'none',
        resize: 'none',
        background: 'transparent',
        fontSize: '18px',
        padding: '18px',
        wordBreak: 'break-all',
        '&, &:focus, &:active': {
            border: 'none !important',
            outlineWidth: '0',
        }
    }),
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '0 4px 4px 0',
    },
}

export const CodeBlock = () => {

    const [id, setId] = useState<string>()

    const [style, setStyle] = useState<string>('informative')

    useEffect(()=>{
        setId(uuidv4())
    },[])

    const optionsStr = style 
        ? `--${style}` 
        : ''

    const value = id 
        ? `<img src="https://imagespawn.com/imagine/${id}${optionsStr}" width="500px" height="auto" referrerpolicy="unsafe-url" loading="lazy" />`
        : null

    return (
        <div style={{textAlign: 'center'}}>
            <div css={styleDef.window}>
               {/*  <div css={styleDef.title}>Unique image tag:</div> */}
                {value && <textarea css={styleDef.text} rows={4} value={value} readOnly />}
                <div css={styleDef.actions}><Tooltip title="Generate new tag"><IconButton onClick={()=>setId(uuidv4())}><CachedIcon /></IconButton></Tooltip></div>
            </div>
            {value && 
                <Box mt={2}>
                    <CopyLinkButton 
                        //@ts-ignore
                        text={<>Copy your tag</>}
                        copyContent={value} 
                        buttonProps={{
                            startIcon: <ContentCopyIcon />,
                            variant: 'contained',
                            color: 'primary',
                            size: 'large',
                            style: {
                                fontWeight: 600,
                                borderRadius: '4px !important',
                            }
                        }}
                    />

                </Box>
                }
        </div>
    )

}