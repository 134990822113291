import { Typography, Box, Grid, Link, lighten, Container, Divider, css } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { ImageCss } from "components/app/ImageCss";
import { isVisible } from "modules/landingPage/utils/landingPageUtils";
import { useLocale } from "modules/yoio/access/locale/useLocale";
import { AppLogoSmall } from "modules/yoio/component/AppLogoSmall";
import { usePageMeta } from "modules/yoio/usePageMeta";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { useRef } from "react";
import { isHavingValue, isNullOrUndefined } from "utils/objectUtils";
import { useEnteredView } from "utils/useIsInViewport";

const styleDef = {
    link: theme=>css({
        color: theme.palette.text.secondary,
        fontFamily: theme.typography.fontFamily,
        textDecoration: 'none',
        display: 'block',
        marginBottom: theme.spacing(2),
        fontSize: '13px',
        '&:hover': {
            cursor: 'pointer',
            color: lighten(theme.palette.primary.main,0.3),
        },
    }),
}

const useStyles = makeStyles((theme) => ({
    links: {
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column'
        },
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end'
        }
    },
    linkCategory: {
        [theme.breakpoints.up('md')]: {
            '&+$linkCategory': {
                marginLeft: theme.spacing(12)
            }
        },
        [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(3)
        },
    },
    link: {
        
    },
    linkLegal: {
        color: theme.palette.text.secondary,
        textDecoration: 'none',
        display: 'block',
        marginBottom: theme.spacing(2),
        '&:hover': {
            color: lighten(theme.palette.primary.main,0.3),
        },
    },
    linkBottomLine: {
        color: theme.palette.text.secondary,
        //textDecoration: 'underline',
        '&+$linkBottomLine': {
            marginLeft: theme.spacing(3)
        },
        '&:hover': {
            color: theme.palette.text.secondary,
            textDecoration: 'underline',
        },
        marginBottom: theme.spacing(0),
        fontSize: '13px',
        '& > *': {
            fontSize: '13px',
        }
    },
    logo: {
        width: '200px',
        height: '200px',
/*         marginRight: theme.spacing(4),
        '&:last-child': {
          marginRight: '0',
        }, */
    },
    watermarkContainer: {
        marginTop: theme.spacing(5),
        //justifyContent: 'flex-end',
    },
    watermarkText: {
        fontSize: '13px',
    },
    yearAndName: {
        fontSize: '13px',
    },
  }));

const environment = process.env.NEXT_PUBLIC_ENVIRONMENT

export const LandingFooterSaas = ({links, linksLegal, logos, watermark, showLogo, maxWidth, showDivider, bottomLineEnabled, tagline, showLocaleSwitch}) => {

    const classes = useStyles()
    const { appName } = usePageMeta()

    const { i18n } = useTranslation('common')

    const currentYear = new Date().getFullYear()

    const ref = useRef()

    const { entered } = useEnteredView(ref)

    const { localesAvailable } = useLocale()

    if (showLocaleSwitch) {
        links = [
            ...localesAvailable.map(locale=>({
                text: locale.title,
                // We are on a landing page here. Send to the same page, but with the locale passed to nextjs link
                href: '/'+locale.locale,
                locale: locale.locale
            })),
            ...links
        ]
    }

    const renderLogos = () => {
        if (!logos) {
            return [];
        }
        return <Grid container spacing={4} justifyContent="center">{logos.map((l,idx)=><Grid item key={idx} md={4}><Box className={classes.logo} textAlign="center"><ImageCss image={l}/></Box></Grid>)}</Grid>;
    }

    let linkCatTextSx;
    if (entered) {
        linkCatTextSx = (theme)=>({  
            fontWeight: '600',  
            fontSize: '16px',
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(3),
        })
    } else {
        linkCatTextSx = (theme)=>({  
            fontSize: '16px',
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(3),
        })
    }

    const renderLinks = () => {
        linksLegal = linksLegal || []
        links = links || []
        
        const allLinks = [...links,...linksLegal ]

        if (allLinks.length === 0) {
            return null
        }

        const categories = {};

        let linksEffective = bottomLineEnabled !== false ? allLinks.filter(l=>l.type !== 'legal') : allLinks
        linksEffective.forEach((link)=>{
            let category = link.category || 'Other'
            categories[category] = categories[category] || []
            categories[category].push(link)
        })

        return Object.keys(categories).map((key, idx)=>{

            const links = categories[key]


            const linkElems = links.filter(l=>{
                if (isNullOrUndefined(l.href)) {
                    return false;
                }
                if (l.visibleIf) {
                    return isVisible(l.visibleIf, environment, i18n.language)
                }
                return true
            }).map((l,i)=>{
                if (l.locale) {
                    return <NextLink href={l.href} key={i} locale={l.locale} prefetch={false} passHref rel={l.rel}>
                         <Typography component="a" sx={styleDef.link}>{l.text}</Typography>
                    </NextLink>
                }

                return <Link color="inherit" href={l.href} key={i} sx={styleDef.link} rel={l.rel}>{l.text}</Link>
            })

            return (
                <Box key={idx} className={classes.linkCategory}>
                    <Typography 
                        sx={linkCatTextSx}>
                        {key}
                    </Typography>
                    {linkElems}
                </Box>
            )
        })

    }

    const renderLinksBottomLine = () => {
        if (bottomLineEnabled === false) {
            return null
        }

        const legalLinks = [...links.filter(l=>l.type === 'legal'), ...linksLegal]

        const className = [classes.linkBottomLine].join(' ');

        return legalLinks.map((l,idx)=>{
            return (
                <Link color="inherit" href={l.href} key={idx} className={className} rel={l.rel}>
                    <Typography className={classes.linkLegalText}>{l.text}</Typography>
                </Link>
            )
        })
    }

    const showWaterMark = watermark && (watermark.text || watermark.imageSrc)

    const renderWaterMarkElem = (props) => {
        return (
            <Box color="text.secondary" display="flex" alignItems="center" {...props}>
            <Typography className={classes.watermarkText}>{watermark.text}</Typography>
            {watermark.imageSrc && 
                <Image width={23} height={23} src={watermark.imageSrc} priority={false}/>
            }
        </Box>
        )
    }

    const renderedLinks = renderLinks()
    const renderedLinksBottomLime = renderLinksBottomLine()

    const onlyNameAndYearAndWatermarkIsVisible = renderedLinks === null && (!renderLinksBottomLine || renderLinksBottomLine.length === 0)
    const placeWaterMarkNextToName = onlyNameAndYearAndWatermarkIsVisible || isHavingValue(tagline)

    return (<>
        <Box display="flex" flexDirection="column" pt={4} pb={6} ref={ref}>
            {showDivider && 
                <Box mb={3}>
                    <Divider />
                </Box>
            }
            <Container maxWidth={maxWidth} disableGutters>
            {(showLogo !== false || renderedLinks !== null) && (
                <Box mt={3}>
                    <Grid container display="flex" spacing={4}>
                        {showLogo !== false &&
                            <Grid item xs={12} md={6}>
                                <AppLogoSmall iconOnly={false}/>
                                {renderLogos()}

                                {tagline &&
                                    <Typography component="div" fontSize="1.65em" sx={{marginTop: '22px', maxWidth: '360px',}} fontWeight="600">{tagline}</Typography>
                                }
                                
                                {!placeWaterMarkNextToName && showWaterMark && (
                                    <div>
                                        {renderWaterMarkElem({mt: 5})}
                                    </div>
                                )}
                            </Grid>
                        }
                        <Grid 
                            item 
                            xs={12} 
                            md={6} 
                            display="flex"
                            sx={(theme)=>({
                                [theme.breakpoints.up('md')]: {
                                    justifyContent: 'flex-end'
                                },
                            })}
                        >
                            <Box 
                                sx={(theme)=>({
                                    display: 'flex',
                                    [theme.breakpoints.down('md')]: {
                                        display: 'flex',
                                        flexDirection: 'column'
                                    },
                                    [theme.breakpoints.up('md')]: {
                                        justifyContent: 'flex-end'
                                    },
                                })}
                            >
                                {renderedLinks}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            )}
            <Box>
                <Grid container alignItems="center">
                    <Grid item xs={12} md={placeWaterMarkNextToName ? 12 : 2}>
                        <Box display="flex" alignItems="center" mt={3}>
                            <Box color="text.secondary" display="flex" alignItems="center" flexGrow={placeWaterMarkNextToName ? 1 : null}>
                                <Typography className={classes.yearAndName}>{currentYear} {appName}{placeWaterMarkNextToName && showWaterMark ? <>&nbsp;|&nbsp;</> : ''}</Typography>
                                {placeWaterMarkNextToName && showWaterMark && renderWaterMarkElem()}
                            </Box>
                        </Box>
                    </Grid>
                    
                    {renderedLinksBottomLime && renderedLinksBottomLime.length > 0 && 
                        <Grid item xs={12} md={3}>
                           
                                <Box display="flex" alignItems="center" mt={3}>
                                    {renderedLinksBottomLime}
                                </Box>
                        </Grid>
                    }
                    
{/*                     {languageSwitch &&
                        <Grid item xs={12} md={3}>
                            <Box display="flex" alignItems="center" mt={3}>
                                <LanguageSwitch languages={languageSwitch} />
                            </Box>
                        </Grid>
                    } */}
                </Grid>
            </Box>
            </Container>
        </Box>
    </>);

}