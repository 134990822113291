import { useLandingPage } from "modules/landingPage/LandingPageContext"
import { PageType } from "modules/yoio-modules/core/PageTypes"
import { useTranslation } from "next-i18next"
import { useApp } from "./useApp"
import { useYoioContext } from "./useYoioContext"
import { YoioApp } from "./YoioTypes"
import { notNullNotUndefined } from "utils/objectUtils"

export const buildPageMeta = (landingPage, pageSettings, app, language, t) => {
    notNullNotUndefined(app)

    const getTitle = () => {
        if (landingPage?.subPage?.title) {
            return landingPage.subPage.title + ' | ' + app.name
        }
        if (landingPage?.simpleLandingPage?.title) {
            return landingPage.simpleLandingPage.title + ' | ' + app.name
        }

        if (pageSettings?.title) {
            // For Pages such as Sign up, Login etc.

            let titleEffective;
            if (pageSettings.title.startsWith('t:') && t) {
                titleEffective = t(pageSettings.title.substring(2, pageSettings.title.length))
            } else {
                titleEffective = pageSettings.title
            }

            return titleEffective + ' | ' + app.name
        }

        if (pageSettings?.pageType === PageType.landing) {
            return (landingPage?.title || (language && language === 'de' && app['titleDe'] ? app['titleDe'] : app.title))  + ' | ' + app.name
        }

        return app.name
    }

    const getDescription = () => {
        if (landingPage?.subPage?.title) {
            return landingPage?.subPage?.title 
        } 
        if (landingPage?.simpleLandingPage?.meta?.description) {
            return landingPage.simpleLandingPage.meta.description
        }
        if (landingPage?.simpleLandingPage?.hero?.subtitle ) {
            return landingPage?.simpleLandingPage?.hero?.subtitle
        }

        if (pageSettings.pageType === PageType.landing && !landingPage?.subPage && !landingPage?.simpleLandingPage && landingPage?.description) {
            // Use the app or landing page description only on the home landing page
            return landingPage?.description || app.description
        }

        // It's better for SEO to have no description at all than to have duplicate descriptions (same goes for and titles)
        return null
    }

    const getFavIconSrc = () => {
        if (landingPage?.favIconSrc) {
            return landingPage.favIconSrc
        }
        else if (app?.favIconSrc) {
            return app.favIconSrc
        }
        else {
            return null;
        }
    }
    
    const appName = landingPage?.appName || app.name
    const pageDescription = getDescription()
    const pageTitle = getTitle()
    const favIconSrc = getFavIconSrc()
    const headMetaDefaultDisabled = pageSettings && pageSettings.headMetaDefault === false

    return { appName, pageDescription, pageTitle, favIconSrc, headMetaDefaultDisabled }

}

export const usePageMeta = () => {

    const { i18n, t } = useTranslation(['common, yoio'])
    const { app } = useApp()
    const { landingPage } = useLandingPage()
    const { pageSettings } = useYoioContext()

    return buildPageMeta(landingPage, pageSettings, app, i18n?.language, t)

}

export const useOgImageData = () => {

    const { app } = useApp() as { app: YoioApp }
    const { landingPage } = useLandingPage()
    const { appName, pageDescription } = usePageMeta()
    const { pageSettings } = useYoioContext()

    const buildOgImageData = () => {

        const titleEffective = appName
        const description = pageDescription || app.description


        // If page has explicit og image set, use it
        if (pageSettings?.ogImageUrl || pageSettings?.ogImageUrlTwitter) {
            return {
                title: titleEffective,
                description,
                type: 'url',
                imageUrl: pageSettings.ogImageUrl || null,
                imageUrlTwitter: pageSettings.ogImageUrlTwitter || null,
            }
        }

        // Don't autogenerate og image url on specific page type, except they have ogImageAutoGenerate set to true
        if (
            (pageSettings.pageType === PageType.workspace
            || pageSettings.pageType === PageType.service
            || pageSettings.pageType === PageType.access
            || pageSettings.pageType === PageType.legal
            || pageSettings.pageType === PageType.legalTerms
            )
            && pageSettings.ogImageAutoGenerate !== true) {
            return null
        }

        // For other pages, check if they opted out
        if (pageSettings.ogImageAutoGenerate === false) {
            return null;
        }

        // --- Generate or use og image

        // Check if lp has autogenrate
        if (landingPage && landingPage.ogImageAutoGenerate === true) {
            return {
                type: 'generate',
                title: titleEffective,
                description,
            }
        }
        else if (app) {
            if (app.ogImage) {
                return {
                    title: titleEffective,
                    type: 'url',
                    imageUrl: app.ogImage,
                    description,
                }
            } 
            else if (app.name && app.title) {
                return {
                    type: 'generate',
                    title: titleEffective,
                    description,
                }
            }

        }

        return null
    }

    return buildOgImageData()

}