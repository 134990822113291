import { Children, cloneElement } from "react";

export const ClassNameInjector = ({ children, className, style }) => {
  
    const StyledChildren = () =>
      Children.map(children, child =>
        child.props ? 
        cloneElement(child, {
         className: className ? `${child.props.className || ''} ${className || ''}` : child.props.className,
         style: style ? {...child.props.style, ...style} : child.props.style,
       }) : child
     );
 
   return <StyledChildren />;
 };