
const styles = {
    'section-medium': {
        paddingTop: '5vh', paddingBottom: '5vh',minHeight: '70vh', display: 'flex', flexDirection: 'column', justifyContent: 'center'
    },
    'section-large': {
        paddingTop: '5vh', paddingBottom: '5vh',minHeight: '70vh', display: 'flex', flexDirection: 'column', justifyContent: 'center'
    },
    'section-small': theme=>({
        minHeight: '30vh', display: 'flex', flexDirection: 'column', justifyContent: 'center',
        padding: `${theme.spacing(6)} 0`
    }),
}

export const Section = ({variant, ...props}) => {
    const style = styles['section-'+variant]

    if (!style) {
        throw new Error('unknown variant')
    }

    return <div css={style} {...props} />
}