import { Box } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { useIsMounted } from "modules/picasso-ui/form/useIsMounted";
import { Absolute } from "modules/picasso-ui/layout/Absolute";
import { createBgShapeStyle } from "modules/picasso-ui/present/effect/bgEffects";
import { TiltAnimation } from "modules/picasso-ui/present/effect/TiltAnimation";
import Image from "next/image";
import { useState } from "react";
import { enhanceBreakpointValues } from "utils/muiUtils";

const styleDef = {
    shadowGlowing: {
        position: 'absolute', 
        height: '100%', top: 0, left: 0,
        filter: 'blur(10px)',
        transform: 'scale(0.90) translateY(17px)',
        opacity: '0.6',
        zIndex: 0,
    }
}

export const LandingImageV2 = ({src, width, height, elevate, align, unoptimized, alt, animated, animatedFadeIn, layout, objectPosition, objectFit, priority, loading, showArrow, bgShape, 
    style, rootStyle, gradient, padding, borderGlass, boxShadow, shadowGlowing, bgTranslucent, bgTranslucentAngle, paper}) => {

    const [loaded, setLoaded] = useState(false)

    const theme = useTheme()

    const isMounted = useIsMounted()

    const onLoadingComplete = () => {
        if (unoptimized) {
            return; //nothing to do
        }

        if (isMounted()) {
            setLoaded(true)
        }
    }

    objectPosition = objectPosition || 'center center'

    layout = layout || "responsive"

    const opacity = unoptimized || loaded ? 1 : 0

/*     console.log(src,{
        unoptimized,
        width,
        height,
        layout,
    }) */

    const styles = {
        root: {
            textAlign: 'center',
            '& > *': {
                margin: 'auto',
            },
            position: 'relative',
            //display: 'flex',
            //alignItems: 'center',
            maxWidth: width && width !== 'auto' && !gradient ? width: undefined
        },
        rootAlignRight: theme=>({
            [theme.breakpoints.up('md')]: {
                textAlign: 'right',
                '& > *': {
                    margin: 'inherit',
                    marginLeft: 'auto',
                }
            }
        }),
        rootAlignLeft: theme=>({
            [theme.breakpoints.up('md')]: {
                textAlign: 'left',
                '& > *': {
                    margin: 'inherit',
                    marginLeft: 'auto',
                }
            }
        }),
        frame: {
            position: 'relative',
            opacity,
            zIndex: 1,
        },
        frameGlass: theme=>({
            display: 'flex',
            padding: '3px 3px',
            [`${theme.breakpoints.up('sm')}`]: {
                padding: '10px 12px',
                backdropFilter: 'blur(2px)', //contrast(130%) brightness(120%) 
                background: 'radial-gradient(ellipse at 16.7% -10%, hsla(0, 0%, 100%, 0.44) 24%,hsla(0, 0%, 100%, 0.4) 25%,hsla(0, 0%, 100%, 0.2) 45%,hsla(0, 0%, 100%, 0.1))',
                backgroundSize: '300% 100%',
                boxShadow: '0 1px 1px hsl(0deg 0% 100% / 32%) inset, 0 0px 1px hsl(250deg 21.51% 31.25% / 30%) inset, 0 -2px 6px hsla(0, 0%, 100%, 0.25)',
                display: 'inline-flex',
            },
            borderRadius: '34px',
            alignItems: 'center'
        }),
        frameElevate: theme=>({
            boxShadow: 'rgb(0 0 0 / 10%) 0px 12px 30px -5px, rgb(0 0 0 / 4%) 0px 10px 10px -5px',
            borderRadius: '10px',
            '& $img': {
                border: `1px solid ${theme.palette.divider} !important`,
            }
        }),
        img: {
            borderRadius: '10px',
            animation: animatedFadeIn ? 'fadeIn 1s' : null,
        },
    }

    const rootCss = [styles.root]
    if (align === 'left') {
        rootCss.push(styles.rootAlignLeft)
    } else if (align === 'right') {
        rootCss.push(styles.rootAlignRight)
    }
    if (bgShape) {
        rootCss.push(createBgShapeStyle(bgShape))
    }

    const imgCss = [styles.img]

    const frameCss = [styles.frame]
    if (elevate) {
        frameCss.push(styles.frameElevate)
    } else {
        frameCss.push({
            '&>span': {
                overflow: 'visible !important',
            }
        })
    }

    if (borderGlass) frameCss.push(styles.frameGlass)
    
    if (boxShadow) {
        imgCss.push({boxShadow: '0 40px 100px 0 rgba(1, 1, 64, 0.08)'})
    }

    if (gradient) {
        rootCss.push(theme=>({
            borderRadius: '25px',
            [`${theme.breakpoints.up('sm')}`]: {
                padding: !rootStyle?.padding ? `${theme.spacing(4)} ${theme.spacing(4)}` : null,
            },
            background: gradient === true ? theme.palette.gradient : gradient,
            [`${theme.breakpoints.down('sm')}`]: {
                padding: !rootStyle?.padding ? `${theme.spacing(1)} ${theme.spacing(1)}` : null,
            },
            width: '100%',
            height: '100%',
        }))
        if (paper) {
            imgCss.push(theme=>({
                borderRadius: '25px',
                padding: !style?.padding ? `${theme.spacing(1)} ${theme.spacing(2)} !important` : null,
                background: '#fff',
            }))
        }

        if (!borderGlass) {
            frameCss.push({
                height: '100%',
    /*             width: '50vw',
                minWidth: '300px', */
            })
        }

    }

    if (style) {
        imgCss.push(style)
    }

    if (rootStyle) {
        rootCss.push(enhanceBreakpointValues(rootStyle, theme))
    }
   
    if (bgTranslucent) {
        rootCss.push(()=>({
            background: `linear-gradient(${bgTranslucentAngle||'355deg'}, rgba(0, 0, 0, 0) 33%, #a6daff38 16%)`
        }))
    }

    const renderImgElem = () => {
        return <Image src={src} 
            css={imgCss}
            layout={layout}
            objectFit={objectFit || (elevate ? "cover" : null)}
            width={width} 
            height={height} 
            sizes={layout === 'fill' || layout === 'responsive' ? '20vw':null}
            quality={100}
            onLoadingComplete={onLoadingComplete}
            priority={priority}
            unoptimized={unoptimized}   
            alt={alt}
            objectPosition={objectPosition}
            loading={loading}
        />
    }

    const renderInner = () =>
        <Box sx={rootCss}>
            <Box sx={frameCss} m="auto">
                {renderImgElem()}
            </Box>
            {shadowGlowing && <div css={styleDef.shadowGlowing}>{renderImgElem()}</div>}
            {showArrow && <Absolute bottom="0px" left="-60px"><img style={{transform: 'scale(1.0)'}} src="/icons/arrow-curved-size-1.svg" width="100px" height="100px" /></Absolute>}
        </Box>

    return (
        <>
            {animated !== false ?
                <TiltAnimation> {/** important: must wrap the box because the animation should start when box is rerendered */}
                    {renderInner()}
                </TiltAnimation>
                :
                renderInner()
            }

        </>
    )
    
}