import { css } from "@emotion/react";
import { Box, Link, Typography } from "@mui/material"
import { MuiLinkOutlined } from "modules/picasso-ui/link/LinkNormal";
import { FormattedText, FormattedTypography } from "modules/picasso-ui/present/text/FormattedText";
import { IconCircleBorder } from "modules/picasso-ui/specials/IconCircle";
import { IconRenderer } from "modules/picasso-ui/specials/icons/IconRenderer";
import { useTranslation } from "next-i18next";
import { FC, ReactNode } from "react";
import { alignToFlexJustify } from "utils/cssUtils";
import { isNumber } from "utils/numberUtils";
import { isString } from "utils/stringUtils";

export type LandingCardProps = {
    variant?: CardVariant
    cardStyle?: any,
    fullWidth?: boolean
    icon?: ReactNode | string
    iconSize?: string
    iconPosition?: IconPosition
    iconSpacing?: IconSpacing | number
    iconStyle?: any
    iconAlign?: 'left' | 'center' | 'right'
    showIconBorder?: boolean
    textAlign?: 'left' | 'center' | 'right'
    fontSize?: string
    fontWeight?: string | number
    title: ReactNode
    titleIcon?: ReactNode | string
    titleStyle?: any
    description: ReactNode
    descriptionStyle?: any
    href?: string
    label?: string
    backgroundGradient?: boolean
    incentive?: {href?: string, text?: string, align?: 'center' | 'right', variant?: 'contained'}
    startContent?: ReactNode
    content?: ReactNode
    endContent?: ReactNode
    style?: any
}

export enum CardVariant {
    freestanding = 'freestanding',
    flat = 'flat',
    outlined = 'outlined',
    floating = 'floating',
    outlinedSmooth = 'outlinedSmooth',
}

export enum IconPosition {
    top = 'top',
    left = 'left',
}

export enum IconSpacing {
    spacy = 'spacy',
}

const hrefStyle = (theme)=>({
    '&:hover':{
        color: theme.palette.primary.main,
    },
})

const styleDef = {
    card: theme=>({
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('lg')]: {
            minWidth: '250px',
        }
    }),
    cardWithStandardContent: {
        justifyContent: 'space-between',
    },
    cardWithStartContent: {
        pt: 2
    },
    cardWithEndContent: {
        pb: 5
    },
    content: css({
        flexGrow: 1
    }),
    cardFlat: theme=>({
        backgroundColor: '#f9f9fa',
        padding: theme.spacing(3),
    }),
    cardOutlined: theme=>({ 
        border: 1, 
        borderColor: 'divider', 
        borderRadius: '10px', 
        padding: theme.spacing(3),
    }),
    cardOutlinedSmooth: {

    },
    cardFloating: theme=>({
        ...theme.customProps.dashboardCardStyle,
        padding: `${theme.spacing(3)} ${theme.spacing(5)}`,
        paddingBottom: theme.spacing(4),
        '& .cardTitle': {
            fontSize: '1.6em',
        },
    }),
    cardBackgroundGradient: theme=>({
        background: theme.palette.gradient,
    }),
}

export const LandingCard: FC<LandingCardProps> = ({variant = CardVariant.freestanding, cardStyle, fullWidth, icon, iconSize, iconPosition = IconPosition.top, iconSpacing, iconStyle, iconAlign, showIconBorder, textAlign = 'left', fontSize, fontWeight, label, title, titleStyle, description, descriptionStyle, incentive, href, startContent, content, endContent, style, backgroundGradient}) => {

    const { t } = useTranslation('common')

    const learnMoreText = t('common:other.learnMore')

    fontSize = fontSize || '1.8em'
    fontWeight = fontWeight || 600
    iconSpacing = iconSpacing || null

    const iconEffective = (isString(icon) 
        ? <IconRenderer 
            //@ts-ignore because isString check
            name={icon} 
            size={iconSize} 
            style={iconStyle}
        /> 
        : icon)

    const hasStandardContent = iconEffective || title || description

    const cardStyleEffective = [
            styleDef.card,
            hasStandardContent ? styleDef.cardWithStandardContent : null,
            { textAlign },
            fullWidth?{width:'100%'}:null,
            startContent?styleDef.cardWithStartContent:null,
            endContent?styleDef.cardWithEndContent:null
        ]

    if (variant === CardVariant.flat) {
        // @ts-ignore
        cardStyleEffective.push(styleDef.cardFlat)
    } else if (variant === CardVariant.outlined) {
        // @ts-ignore
        cardStyleEffective.push(styleDef.cardOutlined)
    } else if (variant === CardVariant.floating) {
        // @ts-ignore
        cardStyleEffective.push(styleDef.cardFloating)
    } else if (variant === CardVariant.outlinedSmooth) {
        // @ts-ignore
        cardStyleEffective.push(styleDef.cardOutlinedSmooth)
    }

    if (cardStyle) { cardStyleEffective.push(cardStyle) }
    if (style) { cardStyleEffective.push(style) }
    if (backgroundGradient) {styleDef.cardBackgroundGradient}



    return (
        <Box sx={cardStyleEffective} className="card">
            {startContent}
               
            {hasStandardContent && (
                <>
                    <Box width="100%">
                        {iconEffective && iconPosition === IconPosition.top && (
                            showIconBorder
                                ? <IconCircleBorder size="70px" borderWidth="3px" style={iconAlign==='center'?{margin:'auto'}:null}>{iconEffective}</IconCircleBorder>
                                : <div css={iconAlign==='center'?{textAlign:'center'}:null}>{iconEffective}</div>
                        )}
                        {label && <Typography color="primary.main" fontWeight="600">{label}</Typography>}

                        <Box 
                            display="flex"
                            css={theme=>({
                                width: '100%',
                                marginTop: iconPosition === IconPosition.top 
                                    // @ts-ignore
                                    ? (isNumber(iconSpacing) ? theme.spacing(iconSpacing) : (iconSpacing === IconSpacing.spacy ? theme.spacing(8) : theme.spacing(2)))
                                    : null,
                                textAlign,
                            })}
                            >
                            {/** @ts-ignore because isString check */}
                            {iconEffective && iconPosition === IconPosition.left && (
                                showIconBorder
                                    ? <IconCircleBorder size="70px" borderWidth="3px" style={iconAlign==='center'?{margin:'auto'}:null}>{iconEffective}</IconCircleBorder>
                                    : <div css={{marginRight: '8px'}}>{iconEffective}</div>
                            )}
                            <div css={{ margin: textAlign === 'center' ? 'auto' : null, width: '100%' }}>
                                <Typography 
                                    component="h3" 
                                    fontSize={fontSize}
                                    fontWeight={fontWeight}
                                    sx={[()=>({marginBottom: '16px'}),titleStyle]}
                                    className="cardTitle"
                                    >
                                    {/** @ts-ignore */}
                                    <FormattedText>{title}</FormattedText>
                                </Typography>
                                {/** @ts-ignore */}
                                <FormattedTypography component="div" fontSize="1em" color="text.secondary" sx={descriptionStyle}>{description}</FormattedTypography>
                            </div>
                        </Box>

                    </Box>
                    {/* @ts-ignore */ }
                    {(incentive || href) &&
                        <Box display="flex" justifyContent={incentive?.align ? alignToFlexJustify(incentive.align): 'space-between'} mt={3} alignItems="center">
                            {incentive && <MuiLinkOutlined href={incentive.href} className="lpCtaButton">{incentive.text||'Try now'}</MuiLinkOutlined>}
                            {href && <Link href={href} underline="hover" color="text.secondary" sx={hrefStyle}>{learnMoreText}</Link>}
                        </Box>
                    }
                </>
            )}
     
            {content && <div css={styleDef.content}>{content}</div>}
            {endContent}
        </Box>
    )

}