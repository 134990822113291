import { Box } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { AdvancedDataProtectionBadge, EuDatacenterBagde, LiveSupportBagde } from "modules/picasso-ui/specials/TrustBadge"

const useStyles = makeStyles((theme)=>({
    root: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
        },
        [theme.breakpoints.up('md')]: {
            '&>*+*': {
                //marginLeft: '1.5rem',
            }
        },
    },
}))

export const VsprioBadges = () => {

    const classes = useStyles()

    return (
        <Box display="flex" justifyContent="flexStart" className={classes.root}>
            <EuDatacenterBagde />
            <LiveSupportBagde />
            <AdvancedDataProtectionBadge />
        </Box>
    )

}