import { Typography, Box, Grid, Link } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { ImageCss } from "components/app/ImageCss";

const useStyles = makeStyles((theme) => ({
    links: {
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column'
        },
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end'
        }
    },
    link: {
        marginRight: theme.spacing(2),
       '&:last-child': {
         marginRight: '0',
       },
    },
    linkText: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(2),
    },
    linksLegal: {
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column'
        },
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end'
        },
    },
    linkLegal: {
        color: '#7A7A7A',
        textDecorationColor: '#7A7A7A',
        marginRight: theme.spacing(2),
        '&:last-child': {
          marginRight: '0',
        },
    },
    linkLegalText: {
        color: '#7A7A7A',
        fontSize: '11px',
        marginBottom: theme.spacing(2),
        fontWeight: 'bold',
    },
    footerMain: {
        color: '#fff',
        minHeight: '200px',
        backgroundColor: '#1B1919'
    },
    logo: {
        width: '200px',
        height: '200px',
/*         marginRight: theme.spacing(4),
        '&:last-child': {
          marginRight: '0',
        }, */
    }
  }));

export const LandingFooter = ({links, linksLegal, logos}) => {

    const classes = useStyles();

    const renderLogos = () => {
        if (!logos) {
            return [];
        }
        return <Grid container spacing={4} justifyContent="center">{logos.map((l,idx)=><Grid item key={idx} md={4}><Box className={classes.logo} textAlign="center"><ImageCss image={l}/></Box></Grid>)}</Grid>;
    }

    const renderLinks = () => {
        if (!links) {
            return [];
        }
        return links.map((l,i)=><Link color="inherit" href={l.href} key={i} className={classes.link}><Typography className={classes.linkText}>{l.text}</Typography></Link>);
    }

    const renderLinksLegal = () => {
        if (!linksLegal) {
            return [];
        }
        return linksLegal.map((l,i)=><Link color="inherit" href={l.href} key={i} className={classes.linkLegal}><Typography className={classes.linkLegalText}>{l.text}</Typography></Link>);
    }


    return (<>
        <Box display="flex" alignItems="center" className={classes.footerMain} pt={4} pb={4}>
            <Grid container display="flex" alignItems="center">
                <Grid item md={7}>
                    {renderLogos()}
                </Grid>
                <Grid item md={5} display="flex">
                    <Box className={classes.links}>
                        {renderLinks()}
                    </Box>
                    <Box className={classes.linksLegal}>
                        {renderLinksLegal()}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    </>);

}