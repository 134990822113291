import { ImageCss } from './ImageCss';
import { Link, Typography, Container, AppBar, Toolbar, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import dynamic from 'next/dynamic';
import { useTheme } from '@mui/styles';

const AppDrawer = dynamic(() =>
  import(/* webpackChunkName: "app-drawer" */ './AppDrawer')
)

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  link: {
    marginRight: theme.spacing(4),
    '&:last-child': {
      marginRight: 0,
    },
    [theme.breakpoints.down('md')]: {
        display: 'none',
    },
  },
  linkText: {
    fontSize: '1.2rem',
    fontWeight: '500',
  },
  title: {
    flexGrow: 1,
  },
}));

export default function AppBarLanding({links, logo}) {

  const classes = useStyles()

  const theme = useTheme()
  const drawerVisible = useMediaQuery(theme.breakpoints.down('md'));

    const renderLinks = () => {
        if (!links) {
            return [];
        }
        return links.map((l,i)=><Link color="inherit" href={l.href} key={i} className={classes.link}><Typography className={classes.linkText}>{l.text}</Typography></Link>);
    }

  return (
    <div className={classes.root}>
      <AppBar position="static" style={{ background: 'transparent', boxShadow: 'none'}}>
        <Container maxWidth="lg">
          <Toolbar>
            <div style={{flexGrow: 1}}>
              {logo && <div style={{width: '180px', height: '60px'}}><ImageCss image={logo}/></div>}
            </div>
            {drawerVisible && 
              <div className={classes.drawer}>
                <AppDrawer links={links} />
              </div>
            }
          {renderLinks()}
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}