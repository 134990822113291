import { Typography, Box, Grid, Link, lighten, Divider } from "@mui/material";
import { AppLogoSmall } from "modules/yoio/component/AppLogoSmall";
import { LanguageSwitch } from "modules/yoio/component/LanguageSwitch";
import { usePageMeta } from "modules/yoio/usePageMeta";
import { useTranslation } from "next-i18next";
import Image from "next/image";

const styleDef = {
    linkCategory: theme=>({
        [theme.breakpoints.up('md')]: {
            '&+$linkCategory': {
                marginLeft: theme.spacing(12)
            }
        },
        [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(3)
        },
    }),
    linkCategoryHeading: theme=>({
        fontWeight: '600',
        fontSize: '16px',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
    }),
    linkLegal: theme=>({
        color: theme.palette.text.secondary,
        textDecoration: 'none',
        //marginBottom: theme.spacing(2),
        display: 'block',
        marginBottom: theme.spacing(2),
        '&:hover': {
            color: lighten(theme.palette.primary.main,0.3),
        }
    }),
    watermarkContainer: theme=>({
        justifyContent: 'flex-end',
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(6)
        }
    }),
    watermarkText: {
        fontWeight: '500',
        //color: '#8da2be',  //too light: SEO / lighthouse downscoring because bad accessability. switch with img or css etc
        color: '#6b7181',
        fontSize: '13px',
    },
    elems: theme=>({
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
        },
    }),
    appName: theme=>({
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(6),
        }
    })
  }

export const FooterSimple = ({links, linksLegal, languageSwitch, watermark, showLogo, showDivider, component}) => {
    
    const { appName } = usePageMeta()

    const { t } = useTranslation('yoio')

    const currentYear = new Date().getFullYear()

    const renderLinksBottomLine = () => {
        const legalLinks = [...(links??[]).filter(l=>l.type === 'legal'||l.category==='legal'||l.category===t('yoio:landingPage.footer.categories.legal.title')), ...linksLegal]

        const css = (theme)=>({
            color: theme.palette.text.secondary,
            textDecoration: 'none',
            '&+&': {
                [theme.breakpoints.up('md')]: {
                    marginLeft: theme.spacing(3)
                },
                [theme.breakpoints.down('md')]: {
                    marginTop: theme.spacing(6)
                }
            },
            '&:hover': {
                color: lighten(theme.palette.primary.main,0.3),
            },
            marginBottom: theme.spacing(0),
            fontSize: '14px',
            '& > *': {
                fontSize: '14px',
            }
        })

        return legalLinks.map((l,idx)=>{
            return (
                <Link color="inherit" href={l.href} key={idx} sx={css} rel={l.rel}>
                    <Typography>{l.text}</Typography>
                </Link>
            )
        })
    }

    const renderWaterMarkElem = () => {
        if (!watermark || (!watermark.text && !watermark.imageSrc)) {
            return;
        }

        return (
            <Box color="text.secondary" display="flex" alignItems="center" sx={styleDef.watermarkContainer}>
            <Typography sx={styleDef.watermarkText}>{watermark.text}</Typography>
            {watermark.imageSrc && 
                <Image width={23} height={23} src={watermark.imageSrc} priority={false}/>
            }
        </Box>
        )
    }

    const waterMarkElem = renderWaterMarkElem()

    const renderedLinksBottomLime = renderLinksBottomLine()


    return (<>
            {showDivider && 
                <Divider />
            }
        <Box display="flex" flexDirection="column" pt={4} pb={4} component={component}>
            <Box>
                <Grid container alignItems="center">
                    <Grid item xs={12}>
                        <Box display="flex" alignItems="center" justifyContent="space-between" sx={styleDef.elems}>
                            {showLogo && 
                                <AppLogoSmall />
                            }
                            <Box color="text.secondary" display="flex" alignItems="center">
                                <Box whiteSpace="nowrap" display="flex" alignItems="center" sx={styleDef.appName}>
                                    <Typography>{currentYear} {appName}</Typography>
                                </Box>
                            </Box>
                            {renderedLinksBottomLime && renderedLinksBottomLime.length > 0 && 
                                    <Box display="flex" alignItems="center"
                                        sx={(theme)=>({
                                            display: 'flex',
                                            [theme.breakpoints.down('md')]: {
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'flex-start'
                                            },
                                            [theme.breakpoints.up('md')]: {
                                                justifyContent: 'flex-end'
                                            },
                                        })}
                                    >
                                        {renderedLinksBottomLime}
                                    </Box>
                            }
                            {waterMarkElem}
                        </Box>
                    </Grid>
                    
                    {languageSwitch &&
                        <Grid item xs={12} md={3}>
                            <Box display="flex" alignItems="center" mt={3}>
                                {/** @ts-ignore */}
                                <LanguageSwitch languages={languageSwitch} />
                            </Box>
                        </Grid>
                    }
                </Grid>
            </Box>
        </Box>
    </>)

}