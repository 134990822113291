import { chipClasses, Tooltip, Typography } from "@mui/material"
import { ChipStyled } from "modules/picasso-ui/present/chip/ChipStyled"
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { InfoTooltip } from "components/present/InfoTooltip";
import CheckIcon from '@mui/icons-material/Check';

const styles = {
    chip: {
        border: '1px solid rgb(20 32 58 / 36%)',
        background: 'none',
        color: 'rgb(20 32 58 / 46%)',
        borderRadius: '6px',
        height: '17px',
        [`& .${chipClasses.label}`]: {
            paddingLeft: '4px',
            paddingRight: '4px',
        }
    }
}

export const ComingSoonChip = () => {

    return (
        <ChipStyled label="Coming soon" sx={styles.chip}/>
    )

}

export const ClosedBetaChip = () => {

    return (
        <Tooltip title="Sign up and contact us to get access">
            <ChipStyled label="Closed beta" sx={styles.chip}/>
        </Tooltip>
    )

}

export const NewFeatureChip = () => {

    return (
        <ChipStyled label="NEW" sx={styles.chip}/>
    )

}

export const BusinessFeatureChip = () => {

    return <ChipStyled label="Business feature" sx={styles.chip}/>

}

export const BusinessFeatureBadge = () => {

    return <InfoTooltip open={true} variant="light" text={<>
    <Typography fontSize="14px" color="text.secondary" component="span">Business feature</Typography>
    <Typography fontSize="12px" color="text.secondary" component="div" sx={{display: 'flex', alignItems:'center'}}>Included in your plan <CheckIcon sx={{fontSize: '16px', ml: '2px', color: '#228ff6'}} /></Typography>
    </>} placement="right" padding="10px 15px">
        <WorkspacePremiumIcon sx={{color: '#228ff6', fontSize: '22px'}} />
        </InfoTooltip>

}