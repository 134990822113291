import { css, Typography } from "@mui/material"
import { FormattedTypography } from "modules/picasso-ui/present/text/FormattedText"
import { FunctionComponent } from "react"
import { HeroProps } from "./HeroTypes"

const styles = {
    root: theme=>({
        '--shadow-effect-color': 'rgba(18, 27, 165, 0.12)',
        '--shadow-effect-height': '15vw',
        '--shadow-effect-width': '10vw',
        '--shadow-effect-shift': '2vw',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        paddingTop: theme.spacing(12) + '',
        position: 'relative',
/*         '&::before': {
            position: 'absolute',
            transform: 'translateX(-100%)',
            left: '0',
            content: '""',
            width: 'var(--shadow-effect-width)',
            background: 'white',
            height: 'var(--shadow-effect-height)',
            filter: 'drop-shadow(var(--shadow-effect-color) calc(var(--shadow-effect-width) - var(--shadow-effect-shift)) 88px 48px)',
            borderRadius: '99px',
        },
        '&::after': {
            position: 'absolute',
            transform: 'translateX(-100%)',
            left: '0',
            content: '""',
            width: 'var(--shadow-effect-width)',
            background: 'white',
            height: 'var(--shadow-effect-height)',
            filter: 'drop-shadow(var(--shadow-effect-color) calc(100vw + var(--shadow-effect-shift)) 88px 48px)',
            borderRadius: '99px',
        } */
    }),
    rootFullHeight: ()=>({
        height: '100%',
        maxHeight: '100%',
        paddingTop: 0, //Remove padding and align vertically
        justifyContent: 'center',
        alignItems: 'stretch',
    }),
    rootWithoutEndContent: theme=>({
        paddingBottom: theme.spacing(7) + '',
    }),
    label: theme=>({
        textTransform: 'uppercase',
        fontSize: '1.1em',
        color: theme.palette.primary.main,
        fontWeight: 600,
        marginBottom: theme.spacing(2.2),
    }),
    title: theme=>({
        maxWidth: theme.breakpoints.values.lg,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: theme.spacing(3),
        lineHeight: '1.3em',
        display: 'block !important',
    }),
    subtitle: theme=>({
        display: 'inline-block',
        maxWidth: theme.breakpoints.values.md,
        marginLeft: 'auto',
        marginRight: 'auto',
        color: theme.palette.text.secondary,
        lineHeight: '28.5px',
    }),
    endContentWrapper: theme=>({
        marginTop: theme.spacing(5.5),
    }),
}

const backgroundVariantStyleDef = {
    gradientUp: {
        background: 'linear-gradient(to top,#eff4f9,#fff,#fff)'
    },
    grey: {
        background: '#fbfbfd'
    },
}

export const HeroCenteredGradientUp: FunctionComponent<HeroProps> = ({title, subtitle, titleFontWeight, label, startContent, endContent, fullHeight, style, backgroundVariant}) => {

    backgroundVariant = backgroundVariant || 'grey'
    const backgroundVariantStyle = backgroundVariantStyleDef[backgroundVariant] || null

    const rootStyle = [
        styles.root,
        fullHeight? styles.rootFullHeight:null, 
        endContent?null:styles.rootWithoutEndContent,
        style?css(style):null, 
        backgroundVariantStyle
    ] as any

    return (
            <div css={rootStyle}>
                {startContent && 
                    <div>{startContent}</div>
                }
                <Typography component="div" sx={styles.label}>{label}</Typography>
                {/** @ts-ignore */}
                <FormattedTypography variant="h1" component="h1" fontSize="3.5em" fontWeight={titleFontWeight || 600} sx={styles.title}>{title}</FormattedTypography>
                {/** @ts-ignore */}
                {subtitle && <FormattedTypography component="span" fontSize="1.1em" sx={styles.subtitle}>{subtitle}</FormattedTypography>}
                {endContent && 
                    <div css={styles.endContentWrapper}>{endContent}</div>
                }
            </div>
    )

}