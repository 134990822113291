import { ClassNameInjector } from "modules/picasso-ui/utils/ClassNameInjector"
import { ClassNames } from '@emotion/react';
import { keyframes } from '@emotion/react'

const ani = keyframes({
    'to': {
      transform: 'rotateX(0deg)'
    }
})

export const TiltAnimation = ({children}) => {

    let cssDef = {
        transformOrigin: 'center center',
        transform: 'perspective(1000px) rotateX(20deg)',
        animation: `${ani} 1s`,
        animationFillMode: 'forwards',
    }

    return (
        <ClassNames>
            {({ css }) => {
                const className = css(cssDef)
                return <ClassNameInjector className={className}>{children}</ClassNameInjector>
            }}
        </ClassNames>
    )

}