import { Divider, Grid } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        [theme.breakpoints.down('md')]: {
            width: '100% !important', //reset spacing
            margin: 'auto',
        },
    },
    item: {
        [theme.breakpoints.down('md')]: { //not really correct because does not consider breakpoint prop of grid default
            marginBottom: theme.spacing(8),
            padding: '0px !important'
        }
    },
    itemBreakpointsm: {
        [theme.breakpoints.down('md')]: { //not really correct because does not consider breakpoint prop of grid default
            marginBottom: theme.spacing(8),
            padding: '0px !important'
        }
    },
    itemBreakpointxs: {
        [theme.breakpoints.down('sm')]: { //not really correct because does not consider breakpoint prop of grid default
            marginBottom: theme.spacing(8),
            padding: '0px !important'
        }
    },
    onColumnBigMarginBottom: {
        [theme.breakpoints.down('md')]: { 
            marginBottom: theme.spacing(24),
        }
    },
    verticalDivider: {
        marginRight: '-1px', //trick to make divider visible without breaking grid
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    }
  }));

export const GridDefault = ({children, breakpoint, justifyContent, spacing, alignItems}) => {

    const classes = useStyles();

    breakpoint = breakpoint || 'md';
    let dynamicProps = {};
    dynamicProps[breakpoint] = 12;

    spacing = spacing || 4;

    alignItems = alignItems || "center"

    const itemClassName = classes[`itemBreakpoint${breakpoint}`]

    return (
        <Grid container justifyContent={justifyContent} spacing={spacing}>
            {children.map((i, idx)=>{
                return (
                    <Grid item key={idx} xs={6} sm={6} md={6} lg={6} {...dynamicProps} alignItems={alignItems} className={itemClassName}>
                        {i}
                    </Grid>
                )
            })}
        </Grid>
    );

}

export const GridDefault3 = ({children, breakpoint, showVerticalDivider, onColumnBigMarginBottom,...otherProps}) => {

    const classes = useStyles();

    breakpoint = breakpoint || 'md';
    let dynamicProps = {};
    dynamicProps[breakpoint] = 4;

    let itemClassNames = classes.item
    if (onColumnBigMarginBottom === true) {
        itemClassNames = [classes.item, classes.onColumnBigMarginBottom].join(' ')
    }

    return (
        <Grid container spacing={8} className={classes.gridContainer} {...otherProps}>
            <Grid item xs={12} {...dynamicProps} alignItems="center" className={itemClassNames}>
                {children[0]}
            </Grid>
            {showVerticalDivider === true && <Divider orientation="vertical" flexItem className={classes.verticalDivider}/>}
            <Grid item xs={12} {...dynamicProps} alignItems="center" className={itemClassNames}>
                {children[1]}
            </Grid>
            {showVerticalDivider === true && <Divider orientation="vertical" flexItem className={classes.verticalDivider}/>}
            <Grid item xs={12} {...dynamicProps} alignItems="center" className={itemClassNames}>
                {children[2]}
            </Grid>
        </Grid>
    );

}

export const GridDefault4 = ({children, breakpoint, showVerticalDivider, onColumnBigMarginBottom,...otherProps}) => {

    const classes = useStyles();

    breakpoint = breakpoint || 'md';
    let dynamicProps = {};
    dynamicProps[breakpoint] = 3;

    let itemClassNames = classes.item
    if (onColumnBigMarginBottom === true) {
        itemClassNames = [classes.item, classes.onColumnBigMarginBottom].join(' ')
    }

    return (
        <Grid container spacing={8} className={classes.gridContainer} {...otherProps}>
            <Grid item xs={12} {...dynamicProps} alignItems="center" className={itemClassNames}>
                {children[0]}
            </Grid>
            {showVerticalDivider === true && <Divider orientation="vertical" flexItem className={classes.verticalDivider}/>}
            <Grid item xs={12} {...dynamicProps} alignItems="center" className={itemClassNames}>
                {children[1]}
            </Grid>
            {showVerticalDivider === true && <Divider orientation="vertical" flexItem className={classes.verticalDivider}/>}
            <Grid item xs={12} {...dynamicProps} alignItems="center" className={itemClassNames}>
                {children[2]}
            </Grid>
            {showVerticalDivider === true && <Divider orientation="vertical" flexItem className={classes.verticalDivider}/>}
            <Grid item xs={12} {...dynamicProps} alignItems="center" className={itemClassNames}>
                {children[3]}
            </Grid>
        </Grid>
    );

}