import { ClassNameInjector } from "modules/picasso-ui/utils/ClassNameInjector"
import { orElse } from "utils/objectUtils"
import { ClassNames } from '@emotion/react';
import { keyframes } from '@emotion/react'

const ani = keyframes({
    '0%': {
        transform: 'translateY(60px)',
        opacity: 0.3,
    },
    '100%': {
        transform: 'translateY(0px)',
        opacity: 1,
    }
})

export const SlideUpAnimation = ({children, animate}) => {

    animate = orElse(animate, true)

    let cssDef = {
        transform: 'translateY(60px)',
        opacity: 0.3,
    }

    if (animate) {
        cssDef = {
            ...cssDef,
            animation: `${ani} 800ms cubic-bezier(0.65, 0, 0.35, 1) 1 forwards`,
            transformOrigin: 'top center',
        }
    }

    return (
        <ClassNames>
            {({ css }) => {
                const className = css(cssDef)
                return <ClassNameInjector className={className}>{children}</ClassNameInjector>
            }}
        </ClassNames>
    )

}