import { useEffect, useRef, useState } from "react";

export const useIsInViewport = (ref) => {
    const [isIntersecting, setIsIntersecting] = useState(false)
  
    const observer = useRef()

    useEffect(() => {
      if (!ref.current) return;

      if (!observer.current) {
        observer.current = new IntersectionObserver(([entry]) =>
          setIsIntersecting(entry.isIntersecting),
        )
      }

      observer.current.observe(ref.current);
  
      return () => {
        observer.current.disconnect();
      };
    }, [ref]);
  
    return isIntersecting;
}

export const useEnteredView = (ref) => {

  const entered = useRef(false)

  const inViewport = useIsInViewport(ref)

  if (inViewport === true && entered.current === false) {
    entered.current = true
  }

  return {
    entered: entered.current
  }

}

export const InView = ({children}) => {

  const ref = useRef(null)

  const inViewport = useIsInViewport(ref)

  return children({
    ref,
  }, 
  inViewport)

}

export const EnteredView = ({children}) => {

  const ref = useRef(null)

  const entered = useRef(false)

  const inViewport = useIsInViewport(ref)

  if (inViewport === true && entered.current === false) {
    entered.current = true
  }

  return children(
    {
      ref,
    }, 
    entered.current
  )

}