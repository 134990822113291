import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { MultilineText } from "modules/picasso-ui/present/MultilineText"
import { useTranslation } from "next-i18next"
import Image from "next/image"
import { MainIncentiveLink } from "../saas/MainIncentiveLink";
import { appRoutes } from "utils/appRoutes";
import { Highlights } from "modules/picasso-ui/present/specials/Highlights";
import { FormattedText } from "modules/picasso-ui/present/text/FormattedText";
import { ClosedBetaChip, ComingSoonChip } from "./PricingElements";
import { notNullNotUndefined } from "utils/objectUtils"
import { FeatureInfoTooltip } from "modules/yoio/payment/component/FeatureInfoTooltip";
import { StyledToggleButtonContained, StyledToggleButtonGroupContained } from "modules/picasso-ui/form/StyledToggleButtonGroupContained";
import { useState } from "react";
import { ChipStyled } from "modules/picasso-ui/present/chip/ChipStyled";
import { CountUpAnimation } from "modules/picasso-ui/present/effect/CountUpAnimation";
import { Absolute } from "modules/picasso-ui/layout/Absolute";

const useStyles = makeStyles((theme)=>({
    plans: {
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'center',
        },
        height: '100%',
    },
    planGridItem: {
        height: '100%',
    },
    planCont: {
        //width: '380px',
        width: '300px',
        '&+$planCont': {
            marginLeft: theme.spacing(4),
            [theme.breakpoints.down('md')]: {
                marginLeft: '0px',
            }
        }
    },
    planCard: {
        height: '100%',
        width: '100%',
        padding: theme.spacing(4),
        paddingTop: theme.spacing(1),
        position: 'relative',
        //background: '#fff',
        borderRadius: '10px',
        //border: `1px solid rgb(20 32 58 / 71%)`
        //boxShadow: 'rgb(0 0 0 / 5%) 0px 6px 24px 0px, rgb(0 0 0 / 7%) 0px 0px 0px 1px',

        //feels like it looks better: rgb(0 0 0 / 5%) 0px 8px 13px 0px, rgb(0 0 0 / 7%) 0px 0px 0px 1px
        //boxShadow: 'rgb(0 0 0 / 5%) 0px 8px 13px 0px, rgb(0 0 0 / 7%) 0px 0px 0px 1px',
        boxShadow: 'rgb(0 0 0 / 4%) 0px 8px 8px 0px, rgb(0 0 0 / 7%) 0px 0px 0px 1px',
    },
    planTitle: {
        //color: theme.palette.text.secondary,
        color: 'rgb(63 84 107 / 85%)',
        fontWeight: 500,
        fontSize: '1em',
    },
    planTagline: {
        //color: theme.palette.text.secondary,
        color: 'rgb(63 84 107 / 85%)',
        display: 'inline-block',
        height: '92px',
    },
    priceTag: {
        color: theme.palette.text.primary,
        fontSize: '40px',
        display: 'inline-block',
        fontWeight: '500',
        minHeight: '58px', //price tags with different font size nicer

    },
    priceTagCustom: {
        color: theme.palette.text.primary,
        display: 'inline-block',
        fontSize: '30px',
        fontWeight: '500',
        paddingTop: '14px',
        minHeight: '58px', //price tags with different font size nicer
    },
    iconContainer: {
        paddingTop: '4px',
        //width: '30px',
        display: 'flex',
        paddingRight: '7px',
    },
    includesText: {
        color: theme.palette.text.primary,
        marginBottom: theme.spacing(4),
    },
    //---
    featureMainRoot: {
        marginTop: theme.spacing(4),
    },
    featureMainQuota: {
        cursor: 'default',
        color: theme.palette.text.primary,
        fontSize: '1.9em',
        fontWeight: '500',
        marginBottom: theme.spacing(0.5),
    },
    featureMainTitle: {
        color: theme.palette.text.primary,
        fontSize: '1em',
    },
    //---
    featureBoxRoot: {
        textAlign: 'left',
        '&+$featureBoxRoot': {
            marginTop: theme.spacing(2.5)
        },
    },
    featureBox: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        paddingBottom: '1px',
    },
    featureTitle: {
        fontSize: '14px',
        color: theme.palette.text.primary,
    },
    featureTitleWithInfo: {
        width: '100%',
        display: 'inline-block',
        '& *': {
            cursor: 'default',
            textDecorationLine: 'underline',
            textDecorationStyle: 'dashed',
            textUnderlineOffset: '4px',
            textDecorationColor: 'rgb(146 156 173 / 0.28)',
        }
    },
    featureTitleHighlighted: {
        fontWeight: '600',
        color: theme.palette.text.primary,
    },
    featureTitleQuotaUnlimited: {
/*         '& $featureTitleText': {
            textTransform: 'lowercase',
        } */
    },
    featureTitleText: {
        //noop for $featureTitleText
    },
    tooltip: {
        fontSize: '16px',
        padding: '12px',
    },
    infoIcon: {
        fontSize: '12px',
    },
}))

export const PricingPlansAdvanced = ({items, highlights, noCreditCardRequired, showVatInfo}) => {

    const classes = useStyles()

    const { t } = useTranslation('yoio')

    const [periodSelected, setPeriodSelected] = useState('monthly')
    const [periodTouched, setPeriodTouched] = useState(false)

    const periodsAvailable = items?.filter(({custom, free, variants})=>{
        const priceVariant = custom === true ? 'custom' : (free === true ? 'free' : 'price')
        return priceVariant === 'price' && variants?.length > 0
    }).flatMap(i=>i.variants?.map(v=>v.period)||null)

    const renderItems = (period, priceCountUpAnimationEnabled) => items.map((i,idx)=>{

        const { buttonHref } = i

        return (
            <Box key={idx} mb={4} textAlign="center" className={classes.planCont}>
                <PricingPlanItem 
                    href={buttonHref || appRoutes.yoio.access.getStartedWithPricingPlan.buildPath()}
                    period={period}
                    {...i}
                    countUpAnimationEnabled={priceCountUpAnimationEnabled}
                />
            </Box>
        )
    })

    const shouldRenderPeriodToggle = periodsAvailable?.length >= 2
    const highlightSavingChip = periodSelected === 'annually'

    const priceCountUpAnimationEnabled = periodTouched && shouldRenderPeriodToggle

    return (
        <Box mb={10}>
            {highlights && (
                <Box mt={8} mb={10}>
                    <Container maxWidth="md">
                        <Grid container justifyContent="center">
                            <Highlights items={highlights} />
                        </Grid>
                    </Container>
                </Box>
            )}
            {shouldRenderPeriodToggle && 
                <Box textAlign="center" mb={8}>
                    <StyledToggleButtonGroupContained
                        value={periodSelected}
                        exclusive
                        onChange={(e,val)=>{
                            if (!val) {return;}
                            setPeriodTouched(true)
                            setPeriodSelected(val)
                        }}
                        size="large"
                        round
                        extraLarge
                        depth
                        >
                        {periodsAvailable?.includes('monthly') &&
                            <StyledToggleButtonContained value="monthly" aria-label="left aligned" round sx={{textTransform: 'capitalize'}}>
                                {t('yoio:access.plans.paymentPeriod.monthly.title')}
                            </StyledToggleButtonContained>
                        }
                        {periodsAvailable?.includes('annually') &&
                            <StyledToggleButtonContained value="annually" aria-label="centered" round sx={{textTransform: 'capitalize'}}>
                                {t('yoio:access.plans.paymentPeriod.annually.title')}
                                <ChipStyled 
                                    label="Save 20%" 
                                    color={highlightSavingChip?'success':'default'}
                                    sx={theme=>({
                                        borderRadius: '4px',
                                        marginLeft: '12px',
                                        color: highlightSavingChip? '#339770' : theme.palette.text.secondary,
                                        backgroundColor: highlightSavingChip? '#d5f4e8' : null,
                                        fontSize: '14px',
                                        cursor: 'pointer',
                                    })}
                                />
                            </StyledToggleButtonContained>
                        }
                    </StyledToggleButtonGroupContained>
                </Box>
            }
            <div className={classes.plans} id="select-plan">
            {/*  <Grid container spacing={6} justifyContent="center" alignItems="stretch"> */}
                    {renderItems(periodSelected, priceCountUpAnimationEnabled)}
            {/*  </Grid> */}
            </div>
            {noCreditCardRequired === true && (
                <Box color="text.secondary" pt={2} pb={2} textAlign="center">
                    <Typography>{t('yoio:access.plans.noCreditCardRequiredHelperText')}</Typography>
                </Box>
            )}
            {showVatInfo === true && (
                <Box color="text.secondary" pt={2} pb={2} textAlign="right">
                    <Typography sx={{opacity: 0.6}} fontSize="0.9em">{t('yoio:access.plans.vatInfo')}</Typography>
                </Box>
            )}
        </Box>
    )

}

export const PricingPlanFeatureItem = ({title, info, highlight, comingSoon, closedBeta, quotaUnlimited, quotaUnlimitedTitle}) => {

    const classes = useStyles()

    //const tooltip = info || ''

    const titleClassNames = [classes.featureTitle]
    if (highlight === true) {
        titleClassNames.push(classes.featureTitleHighlighted)
    }
    if (quotaUnlimited === true) {
        titleClassNames.push(classes.featureTitleQuotaUnlimited)
    }

    return (
        <div className={classes.featureBoxRoot}>
            <FeatureTitleWithInfo info={info}>
                <div className={classes.featureBox}>
                        <div className={classes.iconContainer}><FeatureIncludedIcon /></div>
                        <Box textAlign="left">
                            <Typography className={titleClassNames.join(' ')}>
                                {quotaUnlimited === true && quotaUnlimitedTitle ? 
                                    quotaUnlimitedTitle
                                    :
                                    <span className={classes.featureTitleText}>{title}</span>
                                }
                            </Typography>
                            {comingSoon === true && <ComingSoonChip />}
                            {closedBeta === true && <ClosedBetaChip />}
                        </Box>
                </div>
            </FeatureTitleWithInfo>
        </div>
    )
}

const FeatureIncludedIcon = () => <Image layout="fixed" src={'/icons/check-simple-blue.svg'} width={15} height={15} alt="feature included" />

export const PricingPlanItem = ({tagline, title, custom, free, variants, href, features,featuresMain, style, buttonText, includesText, period, countUpAnimationEnabled}) => {

    const classes = useStyles()

    const { t } = useTranslation('yoio')

    const currency = 'usd'//i18n?.language === 'de' ? 'eur' : 'usd'
    const currencySign = currency === 'eur' ? '€' : '$'

    const renderFeatures = (features) => {
        return features.map((f, idx)=><PricingPlanFeatureItem {...f} key={idx}/>)
    }

    const priceVariant = custom === true ? 'custom' : (free === true ? 'free' : 'price')

    if (custom !== true && free !== true && !(variants?.length > 0)) {
        throw new Error('must be free or custom or have variants ' + title)
    }

    const featuresMainFirst = featuresMain?.length > 0 ? featuresMain[0] : null

    const getPriceDisplay = () => {
        if (priceVariant === 'free') {
            return { isNumber: true, value: 0 }
        } 
        else if (priceVariant === 'price') {
            notNullNotUndefined(variants)
            notNullNotUndefined(period)
                const variant = variants.find(v=>v.period === period)
                const max = variants.map(v=>v.priceMonthly.usd||0).reduce((a, b) => Math.max(a, b), 0)
                const oldPrice = max != variant.priceMonthly.usd && max > 0? max : null
                return { isNumber: true, value: variant.priceMonthly.usd, oldPrice }
        }
        else if (priceVariant === 'custom') {
            return { isNumber: false, value: 'Custom' }
        }
    }

    const priceDisplay = getPriceDisplay()

    return (
        <div className={classes.planCard} style={style}>
            <Box display="flex" flexDirection="column" style={{height: '100%'}} pb={2}>
                {title && 
                    <Box mt={2} textAlign="left">
                        <Typography className={classes.planTitle}>
                            {priceVariant === 'custom' ? 'Enterprise' : title}
                        </Typography>
                    </Box>
                }
                <Box height="80px" display="flex" alignItems="center" justifyContent="flex-start">
                    {priceVariant === 'custom' && 
                        <Box textAlign="left" display="flex" alignItems="baseline">
                            <Typography className={classes.priceTagCustom}>Let&apos;s talk</Typography>
                           {/*  <Box ml={1} color="text.secondary">{t('yoio:access.plans.paymentPeriod.monthly.titlePer')}</Box> */}
                        </Box>
                    }
                    {(priceVariant === 'price' || priceVariant === 'free') && 
                        <Box pt={2} pb={1} textAlign="left"  display="flex" alignItems="baseline">
                            <Box className={classes.priceTag} position="relative">
                                {currencySign}{countUpAnimationEnabled && priceDisplay.isNumber ? <CountUpAnimation duration={500}>{priceDisplay.value}</CountUpAnimation>:priceDisplay.value}
                                {priceDisplay.oldPrice && <Absolute right="-42px" top="3px"><Typography color="text.secondary" fontSize="20px" fontWeight="600" sx={{ textDecoration: 'line-through',textDecorationThickness: '2px'}}>{currencySign}{priceDisplay.oldPrice}</Typography></Absolute>}
                            </Box>
                            <Box ml={1} sx={{color: 'rgb(63 84 107 / 85%)', fontWeight: 500,}}>
                                {t('yoio:access.plans.paymentPeriod.monthly.titlePer')}
                            </Box>
                        </Box>
                    }
                </Box>
                {tagline && (
                    <Box mt={1} textAlign="left">
                        <Typography className={classes.planTagline}>
                            <MultilineText>
                                {tagline}
                            </MultilineText>
                        </Typography>
                    </Box>
                )}
                {featuresMainFirst?.quota &&
                    <div className={classes.featureMainRoot}>
                        <FeatureInfoTooltip title={featuresMainFirst.info} arrow placement="right">
                            <Typography className={classes.featureMainQuota}>{featuresMainFirst.quota}</Typography>
                        </FeatureInfoTooltip>
                        <FeatureTitleWithInfo info={featuresMainFirst.info}><Typography className={classes.featureMainTitle}>{featuresMainFirst.title}</Typography></FeatureTitleWithInfo>
                    </div>
                }
                {href &&
                    <Box mt={4} mb={2}>
                        <MainIncentiveLink 
                            buttonProps={{
                                size: 'large',
                                variant: priceVariant === 'free' ? 'outlined':'contained', 
                            }}
                            fullWidth
                            title={buttonText || t('yoio:access.signup.startForFreeText')} 
                            showArrow={false}
                            elevateHigh={false}
                            liftOnHover={true}
                            href={href} />
                    </Box>
                }
                {(priceDisplay || title || tagline) && 
                    <Box pt={3} pb={3} textAlign="center">
                        <Box style={{width: '120px', margin: 'auto'}}>
                            <Divider />
                        </Box>
                    </Box>
                }
                <Box pl={1} pr={1} flexGrow={1} mt={3}>
                    {includesText && <Box textAlign="left"><Typography className={classes.includesText}><FormattedText>{includesText}</FormattedText></Typography></Box>}
                    {renderFeatures(features)}
                </Box>
            </Box>
        </div>
    )

}

const FeatureTitleWithInfo = ({children, info}) => {

    const classes = useStyles()

    if (info) {
        return (
            <FeatureInfoTooltip title={info} arrow placement="right">
                <div className={classes.featureTitleWithInfo}>
                    {children}
                </div>
            </FeatureInfoTooltip>
        )
    } else {
        return children
    }

}