import { FunctionComponent, ImgHTMLAttributes } from "react";

export interface MajestIconProps {
    name: string
}

export const MajestIcon: FunctionComponent<MajestIconProps & ImgHTMLAttributes<HTMLImageElement>> = ({name, ...props}) => {

    return <img src={`/icons/majesticons/line/${name}.svg`} {...props} />

}