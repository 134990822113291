import { Box, Typography } from "@mui/material";
import { MainIncentiveLink } from "./saas/MainIncentiveLink"
import { MultilineText } from "modules/picasso-ui/present/MultilineText";
import { alignToFlexJustify } from "utils/cssUtils";

const styleDef = {
    card: theme=>({
        backgroundColor: 'rgb(229 244 242 / 40%)',//'#1576de',//'#004998',//'#f1f1f1'
        minHeight: '180',
        borderRadius: '8px',
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
        paddingLeft: theme.spacing(10),
        paddingRight: theme.spacing(10),

        //backgroundImage: 'radial-gradient(#dddddd14 40%, transparent 5%), radial-gradient(#dddddd0a 30%, transparent 7%)',
        //backgroundSize: '500px 500px',
        //backgroundPosition: '-51px 82px, 107px 65px',
        '&:hover': {
           // boxShadow: 'rgb(0 0 0 / 22%) 0px 10px 24px 0px, rgb(0 0 0 / 12%) 0px 2px 4px 0px, rgb(255 255 255 / 7%) 0px 0px 0px 1px inset',
            //transition: 'box-shadow 0.3s ease-in-out',
        },
        flexDirection: 'column',
        textAlign: 'center',
    }),
    title: {
        fontWeight: '600',
        fontSize: '2.2em', //fontSize: '2.4em', looked nice when there was a subtitle
        color: '#14203a'
    },
    subtitle: {
        fontSize: '1.3em',
        fontWeight: '400',
        color: '#14203a', //'#9c59a7' // theme.palette.primary.main,
    },
}

/**
 * 
 * use incentiveLink 
 * 
 * @param {*} param0 
 * @returns 
 */
export const LandingIncentiveCard = ({title, subtitle, incentiveLink,incentiveLinkSecondary, mb, sx, align}) => {

    const alwaysCenteredFromDown = 'sm'

    return (
        <Box 
            sx={[styleDef.card, sx, 
                theme=>({ 
                    alignItems: alignToFlexJustify(align??'center'),
                    [theme.breakpoints.down(alwaysCenteredFromDown)]: {
                        alignItems: 'center', 
                    }
                })]} 
            display="flex" mb={mb}>
            <div><Typography component="span" sx={styleDef.title}><MultilineText>{title}</MultilineText></Typography></div>
            <Box mt={2}><Typography component="span" sx={styleDef.subtitle}>{subtitle}</Typography></Box>
            <Box mt={4} display="flex" justifyContent="flex-start" gap="12px" 
                sx={theme=>({
                    flexDirection: align==="center"?'column':'row',
                    [theme.breakpoints.down(alwaysCenteredFromDown)]: {
                        flexDirection: 'column', 
                    }
                })}
                    >
                {incentiveLink && (
                    <div><MainIncentiveLink {...incentiveLink} elevateHigh={false} liftOnHover={true} buttonProps={{color: 'primary'}} secondaryTextStyle={{ mt: 1, fontSize: '12px' }} /></div>
                )}
                {incentiveLinkSecondary && (
                    <MainIncentiveLink {...incentiveLinkSecondary} elevateHigh={false} liftOnHover={true} buttonProps={{color: 'primary', variant: 'outlined' }} secondaryTextStyle={{ mt: 1, fontSize: '12px' }} />
                )}
            </Box>
        </Box>
    )

}