import { Box, Button, Card, CardActions, CardContent, darken, lighten, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { orElse } from "utils/objectUtils";

const styleDef = {
    root: theme=>({
        minWidth: 170,
        //backgroundColor: '#5d97ff',
        padding: theme.spacing(1),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
    }),
    'root-design-dashboard': theme=>({
        ...theme.customProps.dashboardCardStyle
    }),
    'root-design-outlined': theme=>({
        ...theme.customProps.outlinedCardStyle
    }),
    'root-design-frameless': theme=>({
        border: 0,
        boxShadow: 'none',
    }),
    content: theme=>({
        flexGrow: '1',
        display: 'flex',
        paddingBottom: theme.spacing(2),
    }),
}

const useStyles = makeStyles((theme)=>({
    mainText: {
    },
    mainTextValue: {
        color: lighten('#273340',0.1),
        fontSize: '30px',
        fontWeight: '600',
        whiteSpace: 'nowrap',
        lineHeight: '1',
    },
    mainTextValueColorPrimary: {
        color: '#0088FE'
    },
    actions: {
        justifyContent: 'space-between',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    incentive: {
        //backgroundColor: 'rgb(255, 193, 7)',
        fontWeight: '600',
    },
    valueTitle: {
        color: darken(theme.palette.text.secondary,0.1),
        fontWeight: '400',
    }
  }));

export const SimpleCard = ({
    children,
    heading,
    afterHeadingContent,
    actionLabel, 
    onActionClick, 
    actionButton,
    headingEndElem, 
    headingOnActionClick, 
    bottomActionLabel, 
    bottomOnActionClick,
    contentBoxProps,
    designVariant,
    height,
    flexGrowEnabled,
    style,
    cardContentStyle,
    bottomActions
    }) => {

    const classes = useStyles()

    const cardStyle = [styleDef.root]

    designVariant = designVariant || 'dashboard'

    if (designVariant) {
        cardStyle.push(styleDef[`root-design-${designVariant}`])
    }

    if (style) {
        if (Array.isArray(style)) {
            cardStyle.push(...style)
        } else {
            cardStyle.push(style)
        }
        
    }

    height = orElse(height, '100%')

    flexGrowEnabled = orElse(flexGrowEnabled, true)

    const renderActions = () => {
        return <>
            {(actionLabel || actionButton) && (
                <Box ml={3} display="flex" alignItems="flex-end" style={{height: '100%'}}>

                    {actionLabel && (
                        <Button
                            size="small"
                            variant="outlined"
                            fullWidth={true}
                            onClick={onActionClick}
                            style={{
                                whiteSpace: 'nowrap',
                            }}>
                            {actionLabel}
                        </Button>
                    )}
                    {actionButton && <>{actionButton}</>}
                </Box>
            )}  
        </>;
    }

    const renderHeadingEndElem = () => {
        return (
            <>
                {headingEndElem && (
                    <Box display="flex" alignItems="flex-end">
                        {headingEndElem}
                    </Box>
                )}  
            </>
        )
    }

    const childrenFlexGrow = flexGrowEnabled === true ? 1 : null

    return (
        <Card sx={cardStyle} style={{height: height}}>
            <CardContent sx={[styleDef.content, cardContentStyle]}>
                <Box display="flex" flexDirection="column" flexGrow={1}>
                    {heading && (
                        <Box mb={3} display="flex" flexWrap="wrap" gap="8px">
                            <Box flexGrow={1} display="flex">
                                <Typography variant="h3" className={classes.mainText}>{heading}</Typography> 
                                {afterHeadingContent && (
                                    <Box ml={2}>{afterHeadingContent}</Box>
                                )}
                            </Box>
                            <Box>
                                {renderHeadingEndElem()}
                            </Box>
                        </Box>
                    )}
                    <Box display="flex" alignItems="flex-start" flexGrow={1} {...contentBoxProps}>
                        <Box display="flex" flexGrow={childrenFlexGrow} alignItems="flex-start">
                            {children}
                        </Box>
                        {renderActions()}
                    </Box>
                </Box>
            </CardContent>
            {(bottomActionLabel||bottomActions) && (
                <CardActions className={classes.actions}>
                    {bottomActions 
                        ? bottomActions()
                        : <Box mr={1} >
                            <Button
                            size="small"
                            variant="outlined"
                            fullWidth={false}
                            onClick={bottomOnActionClick}>{bottomActionLabel}</Button>
                        </Box>
                    }
                </CardActions>
            )}
        </Card>
    );

}
