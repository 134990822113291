
import { Paper, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Testimonial } from 'components/present/Testimonial';
import { useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import dynamic from 'next/dynamic';

const ItemsCarousel = dynamic(() =>
  import(/* webpackChunkName: "lib-react-items-carousel" */  'react-items-carousel')
)


const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
        margin: 'auto',
        maxWidth: '800px',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    item: {
        //padding: '40px',
        [theme.breakpoints.down('md')]: {
            padding: '10px',
        },
    },
    paper: {
        paddingLeft: theme.spacing(20),
        paddingRight: theme.spacing(20),
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        [theme.breakpoints.down('md')]: {
            padding: '10px 5px',
        },
    },
    chevronLeft: {
        marginRight: '40px',
        [theme.breakpoints.down('md')]: {
            marginRight: '0px',
        },
    },
    chevronRight: {
        marginLeft: '40px',
        [theme.breakpoints.down('md')]: {
            marginLeft: '0px',
        },
    }
  }));


export const LandingTestimonials = ({items, renderItem, variant}) => {

    const classes = useStyles();

    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const chevronWidth = 40;


    variant = variant || 'paper'

    const renderItems = () => {

        return items.map((i, idx)=> {
            return (
                <div className={classes.item} key={idx} >
                    {renderItem ? 
                        renderItem(i)  
                        :
                        (variant === 'paper' ? 
                        <Paper elevation={8} className={classes.paper}>
                            <Testimonial {...i} />
                        </Paper>
                        :
                        <Testimonial {...i} />
                        )
                    }

                </div>
            );
        })
    };

    return (
        <div className={classes.root}>
            {items?.length === 1 ? 
                renderItems()
                :
                <div style={{ padding: `0 ${chevronWidth}px` }}>
                    <ItemsCarousel
                    requestToChangeActive={setActiveItemIndex}
                    activeItemIndex={activeItemIndex}
                    numberOfCards={1}
                    gutter={20}
                    leftChevron={<IconButton className={classes.chevronLeft} aria-label="previous item" size="large"><ArrowBackIcon fontSize="small"/></IconButton>}
                    rightChevron={<IconButton className={classes.chevronRight} aria-label="next item" size="large"><ArrowForwardIcon fontSize="small"/></IconButton>}
                    outsideChevron
                    chevronWidth={chevronWidth}
                    infiniteLoop={true}
                    >
                    {renderItems()}
                </ItemsCarousel>
            </div>
            }
        </div>
    );

}