import { Box, Tooltip, tooltipClasses } from "@mui/material"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FC, ReactFragment, ReactNode } from "react";

export interface InfoTooltipProps {
    text: ReactNode
    iconColor?: string
    iconStyle?: any
    variant?: 'light'
    children?: ReactNode
    padding?: number | string
}

export const InfoTooltip: FC<InfoTooltipProps> = ({text, iconColor, iconStyle, variant, children, padding}) => {
    
    iconStyle = iconStyle || {}

    if (iconColor) {
        iconStyle.color = iconColor
    }

    iconStyle.fontSize = iconStyle.fontSize || '20px'

    const style = variant === 'light' ? theme=>({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#fff',
            color: 'rgba(0, 0, 0, 0.87)',
            //maxWidth: 220,
            fontSize: theme.typography.pxToRem(14),
            padding: padding??theme.spacing(3),
            borderRadius: '12px',
            boxShadow: '0 15px 35px 0 rgb(201 218 233 / 8%),0 5px 15px 0 rgb(0 0 0 / 5%)',
            border: '1px solid #f2f2f2',
          },
          [`& .${tooltipClasses.arrow}`]: {
            color: '#fff',
            "&::before": {
                border: '1px solid #f2f2f2',
                color: '#fff',
                backgroundColor: '#fff',
              },
          }
    }) : null

    const content: any = children??<InfoOutlinedIcon css={iconStyle} />

    return (
        <Tooltip title={text} placement="right" arrow={true} componentsProps={{popper: {sx: style}}} >
            {content}
        </Tooltip>
    )
}

export const InfoTooltipWrapper = ({text, children, iconStyle}) => {
    
    return (
        <Box display="flex" alignItems="center">
            <Box alignContent="center">
                {children}
            </Box>
            <div
            style={{
                marginLeft: '6px',
                display: 'flex',
                alignSelf: 'center',
                paddingTop: '0px',
                color: '#9aa4bb',
                alignContent: 'center',
            }}>
                <InfoTooltip text={text} iconStyle={iconStyle} />
            </div>
        </Box>
    )
}

