import { Box } from '@mui/material';
import { useLandingPage } from 'modules/landingPage/LandingPageContext';
import { LogoSmall } from 'modules/yoio/logo/LogoSmall';
import { useRouter } from 'next/router';
import { appRoutes } from 'utils/appRoutes';
import { orElse } from 'utils/objectUtils';
import { useApp } from '../useApp';


/**
 * 
 * @param {*} variant 
 *      'logo'
 *      'logoWithText'
 *      'logoName' //-> app name or landing page name as text generated with font
 * @returns 
 */
export const AppLogoSmall = ({iconOnly, watermark, linkDisabled, openInNewTab, variant}) => {

    const { app } = useApp()

    const { landingPage } = useLandingPage()

    variant = variant || (app.logoWithTextSrc && !iconOnly ? 'logoWithText' : 'logoName')

    const router = useRouter()

    openInNewTab = orElse(openInNewTab, true)

    const logoSrcEffective = landingPage?.logoSrc || app.logoSrc //(variant === 'logoWithText' ? app.logoWithTextSrc:false) || 
    const nameEffective = variant === 'logoName' ? (landingPage?.logoName || (landingPage?.logoSrc ? null : app.name)) : null
    const textSrc = variant === 'logoWithText' ? (app.logoWithTextSrc || null) : null

    const handleClick = () => {
        if (window.location?.pathname === '' || window.location?.pathname === '/') {
            router.reload(window.location.pathname) //just refresh
        } else if (openInNewTab === true) {
            window.open(appRoutes.yoio.app.home.buildPath(), '_blank'); //new tab
        } else {
            router.push(appRoutes.yoio.app.home.buildPath())
        }
    }

    const href = (!landingPage?.localeEffective || landingPage?.localeEffective === 'en') ? '/' : '/'+landingPage.localeEffective

    return (
        <>
            {logoSrcEffective && (
                <Box 
                    display="inline-block" 
                    onClick={linkDisabled ? null : handleClick} 
                    textAlign="center" 
                    sx={linkDisabled? null : {cursor: 'pointer'}}>
                    <LogoSmall 
                        iconSrc={logoSrcEffective}
                        text={nameEffective} 
                        textSrc={textSrc}
                        logoWithTextHeight={app.logoWithTextHeight}
                        logoWithTextWidth={app.logoWithTextWidth}
                        iconOnly={iconOnly} 
                        watermark={watermark}
                        height={landingPage?.logoHeight || app?.logoIconHeight || '35px'}
                        width={landingPage?.logoWidth || app?.logoIconWidth || '35px'}
/*                         height={landingPage?.logoHeight}
                        width={landingPage?.logoWidth} */
                        ariaLabel={app.name}
                        alt={app.name}
                        noLink={linkDisabled}
                        href={href}
                        textImgStyle={app.key==='plinzip'?{marginBottom:'2px'}:null}
                        capitalize={app.key==='plinzip'}
                        />
                </Box>
            )}
        </>
    );

}