import { ResponsiveFlexBox } from "modules/picasso-ui/dataviews/grid/ResponsiveFlexBox"
import { FC } from "react"
import { CardVariant, IconPosition, IconSpacing, LandingCard, LandingCardProps } from "./LandingCard"

type LandingCardsProps = {
    items: LandingCardProps[],
    cols?: number,
    className?: string,
    cardStyle?: any,
    iconCircle?: boolean,
    variant?: CardVariant
    optimizeLayout?:boolean
    iconSpacing?: IconSpacing | number
    iconStyle?: any
    fontSize?: string
    fontWeight?: string | number
    titleStyle?: any
    descriptionStyle?: any
    iconAlign?: 'left' | 'center' | 'right'
    textAlign?: 'left' | 'center' | 'right'
    showIconBorder?: boolean
    iconSize?: string
    iconPosition?: IconPosition
    flexBasis?: string
    gap?: string|number
    flexDirectionColumnDown?: 'sm' | 'md' | 'lg'
}

export const LandingCards: FC<LandingCardsProps> = ({items, variant, cardStyle, className, flexBasis, optimizeLayout, cols, flexDirectionColumnDown, iconSpacing, iconPosition, textAlign, iconAlign, showIconBorder, iconSize, fontSize, fontWeight, titleStyle, descriptionStyle, gap, iconStyle}) => {

    cols = cols || 3

    const evenCount = items.length % 2 == 0
    const onePerRow = optimizeLayout && !evenCount && items.length <= 4

    return (
        <ResponsiveFlexBox 
            //@ts-ignore
            className={className}
            itemsCount={items.length}
            cols={onePerRow ? 1 : cols}
            flexBasis={flexBasis}
            gap={gap}
            flexDirectionColumnDown={flexDirectionColumnDown}
            >
            {items.map((item, key)=><LandingCard 
                key={key} 
                variant={variant} 
                cardStyle={cardStyle} 
                iconSpacing={iconSpacing} 
                textAlign={textAlign} 
                iconAlign={iconAlign} 
                showIconBorder={showIconBorder} 
                fontSize={fontSize} 
                fontWeight={fontWeight} 
                iconSize={iconSize} 
                iconStyle={iconStyle} 
                iconPosition={iconPosition} 
                titleStyle={titleStyle}
                descriptionStyle={descriptionStyle}
                {...item} 
            />)}
        </ResponsiveFlexBox>
    )
    
}