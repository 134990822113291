import { css, useTheme } from "@emotion/react";
import { Button, Typography, useMediaQuery } from "@mui/material";
import { ElementLink } from "modules/picasso-ui/link/LinkNormal";
import { FormattedTypography } from "modules/picasso-ui/present/text/FormattedText";
import { FunctionComponent, useId, useRef, useState } from "react";
import { appRoutes } from "utils/appRoutes";
import { toKebabCase } from "utils/stringUtils";
import { useEffect } from "react";
import { useTranslation } from "next-i18next";

export interface LandingAccordeonProps {
    title?: string
    items?: LandingAccordeonItem[]
}

export interface LandingAccordeonItem {
    title: string
    description?: string
}

const stylesDef = {
    contentSection: theme=>css({
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        justifyContent: 'space-evenly',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            '&>*': {
                flex: '1 1 0px',
            }
        }
    }),
    overview: {
        listStyle: 'none',
        padding: 0,
    },
    overviewLi: theme=>({
        height: '80px',
        '& input': {
            display: 'none',
        },
        '& label': {
            border: '0px',
            borderLeftWidth: '4px',
            borderStyle: 'solid',
            borderColor: `${theme.palette.divider}`,
            display: 'block',
            width: '100%',
            cursor: 'pointer',
            padding: '12px 32px',
            fontWeight: 400,
            fontSize: '1.2em',
            height: '80px',
        },
        '& > .content': {
            display: 'none',
            padding: '12px 32px 32px 32px',
        },
        '& input:checked+label': {
            borderColor: `${theme.palette.primary.main}`,
            fontWeight: 600,
        },
        '& input:checked+label+.content': {
            display: 'block',
        },
        '&:hover label': {
            borderColor: `${theme.palette.primary.main}`,
        },
        '&+&': {
            marginTop: '10px',
        },
        [theme.breakpoints.down('md')]: {
            height: 'initial',
            '& > .content': {
                paddingBottom: '64px',
            },
        }
    }),
    contentStandalone: theme=>css({
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'initial',
        }
    }),
}

export const LandingAccordeon: FunctionComponent<LandingAccordeonProps> = ({title, items}) => {

    const { t } = useTranslation('yoio')
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    
    const [selected, setSelected] = useState<number>(0)

    const radioId = useId()

    const radioName = useRef('landacc-r-'+radioId)

    const getContentKey = (i) => {
        return 'landacc-'+toKebabCase(i.title)
    }

    const getInputId = (i) => {
        return 'landacc-id-'+toKebabCase(i.title)
    }

    const renderLink = () => {
        return (
            <ElementLink href={appRoutes.yoio.access.signup.buildPath('acrd')} disableClientSideNav>
                <Button color="primary" variant="contained">{t('yoio:access.signup.getStartedTextStartNow')}</Button>
            </ElementLink>
        )
    }

    useEffect(()=>{
        if (isSmallScreen) {
            setSelected(null)
        }
    }, [isSmallScreen])

    return (
        <div>
            {title && <Typography fontWeight={600} color="#99a6ba" component="div" mb={3} fontSize="1.2em">{title}</Typography>}
            <div css={stylesDef.contentSection}>
                <ul css={stylesDef.overview}>
                    {items.map((i,idx)=><Typography component="li" variant="h4" key={i.title} sx={stylesDef.overviewLi}>
                        <input name={radioName.current} type="radio" id={getInputId(i)} defaultChecked={idx===selected}/><label htmlFor={getInputId(i)} onClick={()=>setSelected(idx)}>{i.title}</label>
                        
                        {/** @ts-ignore */ }
                        {isSmallScreen && (<div className="content"><FormattedTypography key={i.title} fontSize="18px" mb={6} component="div">{i.description}</FormattedTypography>{renderLink()}</div>)}
                    </Typography>)}
                </ul>
                {!isSmallScreen && (
                    <div css={stylesDef.contentStandalone}>
                       {/*  <IconCircleSimple size="50px" borderWidth="3px"><SportsScoreIcon sx={{ color: '#99a6ba', fontSize: '2.7em',}}/></IconCircleSimple> */}
                        {items.map((i, idx)=>{
                            const isSelected = idx===selected
                            //@ts-ignore
                            return <FormattedTypography key={i.title} className={getContentKey(i)} style={{display: isSelected?null:'none'}} aria-hidden={isSelected?null:true} fontSize="18px" mt={3} mb={6}>{i.description}</FormattedTypography>
                        })}
                        {renderLink()}
                    </div>
                )}
            </div>
        </div>
    )

}