import { Box, Typography } from "@mui/material"
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

export const HighlightItem = ({title, justifyContent, sx}) => {

    return (
        <Box display="flex" alignItems="center" justifyContent={justifyContent}>
            <CheckCircleRoundedIcon sx={(theme)=>({
                color: '#005fff',
                marginRight: theme.spacing(1),
                fontSize: '20px',
            })}/>
            <Typography component="div" sx={[{
                fontSize: '16px',
                fontWeight: '700',
            },sx]}>{title}</Typography>
        </Box>
    )

}