
import { Box, } from '@mui/material';
import { GridDefault3 } from 'components/layout/positioning/GridDefault';
import { ValuePropositionCardVariant2 } from 'modules/picasso-ui/present/ValuePropositionCardVariant2';

export const LandingPageValuePropositionsSaas = ({items, id}) => {

    const renderItems = () => {
        return items.map((i, idx)=>{
            return (
                <ValuePropositionCardVariant2 key={idx}
                    title={i.title}
                    text={i.text}
                    iconSrc={i.iconSrc}
                />
            );
        });
    }

    return (
        <Box id={id}>
            <GridDefault3>
                {renderItems()}
            </GridDefault3>
        </Box>
    );

}
