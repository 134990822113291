import { Box, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Image from "next/image";
import { MultilineText } from "./MultilineText";

const useStyles = makeStyles(()=>({
    root: {
        height: '100%',
        textAlign: 'center',
        boxShadow: '0 0 90px rgb(4 63 149 / 8%)',
        borderRadius: '6px',
    },
    title: {
        //textAlign: 'left',
        fontSize: '18px',
        fontWeight: '600',
    },
    text: {
        fontSize: '15px',
        fontWeight: '500',
    }
}));

export const ValuePropositionCardVariant2 = ({title, text, iconSrc}) => {

    const classes = useStyles();

    return (
        <Box className={classes.root} p={iconSrc ? 4 : 8} pb={8}>
            {iconSrc && 
                <Box mb={4}>
                    <Image src={iconSrc} width={60} height={60} />
                </Box>
            }
            <Box mb={2}>
                <Typography variant="h3" className={classes.title}>
                    {title}
                </Typography>
            </Box>
            <Box color="text.secondary">
                <Typography className={classes.text}>
                    <MultilineText>{text}</MultilineText>
                </Typography>
            </Box>
        </Box>
    );

}