import { usePricingPlansDto } from "modules/yoio/access/usePricingPlans"
import { PricingPlansAdvanced } from "./PricingPlansAdvanced"

export const AppPricingPlans = ({plans, features, options, namespace}) => {

    const { getDto } = usePricingPlansDto({
        namespace, 
        plans, 
        features, 
        options,
    })

    return (
        <PricingPlansAdvanced {...getDto()} showVatInfo={true}/>
    )

}