import { Typography, Box } from "@mui/material";
import { FormattedTypography } from "modules/picasso-ui/present/text/FormattedText";

const styleDef = {
    size1: theme=>({
        fontSize: '48px',
        [theme.breakpoints.down('md')]: {
            fontSize: '29px',
        },
        fontWeight: 'bold'
    }),
    size2: theme=>({
        fontSize: '39px',
        [theme.breakpoints.down('md')]: {
            fontSize: '29px',
        },
    }),
    size3: theme=>({
        fontSize: '33px',
        [theme.breakpoints.down('md')]: {
            fontSize: '29px',
        },
    }),
    size4: {
        fontSize: '29px'
    },
    size5: {
        fontSize: '21px',
        color: '#000000',
        fontWeight: '500'
    },
    size6: {
        fontSize: '18px',
        color: '#7F7A7A'
    },
    sectionLabel: {
        fontSize: '16px',
        color: '#1258cd',
        fontWeight: '600',
    },
}

export function TypoLandingHeading(props) {
    return <Typography variant={props.variant} sx={props.size?styleDef[`size${props.size}`]:null}>{props.children}</Typography>
}

export const LandingTypography = ({text, ...props}) => {
    return <Typography {...props}>{text}</Typography>
}

export const LandingSecondarySectionTitle = ({align, text, noMarginBottom, littleMarginBottom, subtitleText, label, subtitleSecondary, titleCss, centerContent, maxWidth}) => {

    align = align || 'center'

    return (
        <Box textAlign={align} mb={noMarginBottom === true? 0 : (littleMarginBottom === true? 6 : 12)} mt={8}>
            {label && <Box mb={1}><SectionLabel>{label}</SectionLabel></Box>}
            {/** @ts-ignore */}
            <FormattedTypography variant="h2" component="h2" sx={titleCss} maxWidth={maxWidth}>{text}</FormattedTypography>
            {centerContent && <Box mt={2}>{centerContent}</Box>}
            {subtitleText && (
                <LandingSubtitle 
                    text={subtitleText} 
                    secondary={subtitleSecondary} 
                    sx={theme=>({maxWidth: align ==='center' ? theme.breakpoints.values.sm+'px':null, margin: align==='center'?'auto':null, marginTop: theme.spacing(2)})}
                />
            )}
        </Box>
    )

}

export const LandingSubtitle = ({text, ...props}) => {

    const sx = [
        {
            fontSize: '18px',
            fontWeight: '400', 
            color: 'text.secondary',
        }, props.sx]

    //@ts-ignore
    return <FormattedTypography sx={sx} component="div">{text}</FormattedTypography>
}

const SectionLabel = ({children}) => {
    return <Typography component="span" sx={styleDef.sectionLabel}>{children}</Typography>
}