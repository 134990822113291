import { createContext, useContext } from "react";

export const LandingPageContext = createContext(null);

export const LandingPageProvider = ({children, landingPage}) => {

  return (
    <LandingPageContext.Provider value={landingPage}>
      {children}
    </LandingPageContext.Provider>
  )

}

export const useLandingPage = () => {

    const landingPage = useContext(LandingPageContext)
  
    return {
      landingPage,
    }

}