import { FunctionComponent, PropsWithChildren } from "react";
import { pxAsNumber } from "utils/cssUtils";

export interface ResponsiveFlexBoxProps {
    cols?: number
    itemsCount?:number
    style?: any
    className?: string
    flexBasis?:string
    justifyContent?: string
    alignItems?: string
    gap?: string|number
    gapSmallScreen?: string|number
    alternate?: boolean
    flexDirectionColumnDown?: 'sm' | 'md' | 'lg'
    responsiveFlexDirection?: 'row'|'row-reverse'|'column'|'column-reverse'
    itemStyle?: any
}

export const ResponsiveFlexBox: FunctionComponent<PropsWithChildren<ResponsiveFlexBoxProps>> = ({cols, itemsCount, style, className, flexBasis, justifyContent, alignItems, gap, gapSmallScreen, alternate, flexDirectionColumnDown, responsiveFlexDirection = 'column', itemStyle, children, ...props}) => {

    cols = cols || 3

    flexDirectionColumnDown = flexDirectionColumnDown ?? 'sm'

    const gapPx = gap ? pxAsNumber(gap) : 40

    const styles = {
        root: (theme)=>({
            display: 'flex',
            gap: gapPx,
            flexWrap: 'wrap',
            justifyContent: justifyContent ? justifyContent : (itemsCount === 1 ? 'center' : (flexBasis==='auto'?'space-evenly':null)),
            [`@media (min-width:${theme.breakpoints.values[flexDirectionColumnDown]}px)`]: {
                '& > a:nth-of-type(2n), & > div:nth-of-type(2n)': alternate ? {
                    marginTop: '300px',
                } : null,
            },
            '& > * ': {
                flexBasis: flexBasis||('calc(100%/'+cols+' - '+((cols-1)*gapPx)/cols+'px)'),
                minWidth: '250px',
                maxWidth: '100%',
            },
            [`@media (max-width:${theme.breakpoints.values[flexDirectionColumnDown]}px)`]: {
                gap: gapSmallScreen || gapPx,
                flexDirection: responsiveFlexDirection,
            },
            [`@media (max-width:${theme.breakpoints.values.lg}px) and (min-width:${theme.breakpoints.values[flexDirectionColumnDown]}px)`]: {
                '& > * ': {
                    flexBasis: flexBasis||('calc(100%/'+2+' - '+((2-1)*gapPx)/2+'px)'),
                    alignItems,
                }
            },
        }),
    }

    return (
        <div 
            //@ts-ignore
            css={[styles.root, style]}
            className={className}
            {...props}
        > 
            {itemStyle 
                ? ((Array.isArray(children??[])?children:[children])as[]).map((c, idx)=><div key={idx} css={itemStyle}>{c}</div>)
                : children
            }
        </div> 
    )

}