import { Box } from '@mui/material';
import { FormattedTypography } from 'modules/picasso-ui/present/text/FormattedText';
import { MultilineTypography } from 'modules/picasso-ui/present/MultilineText';

export const Landing = ({ heading, subtitle, incentive, textAlign, bold, fontWeight }) => {
  
  const styles = {
    root: {
    },
    heading: theme=>({
      color: theme.palette.text.primary,
      textAlign: textAlign || 'left',
      fontSize: '3.7em',
      fontWeight: fontWeight || '600',
      lineHeight: '1.15',
      [theme.breakpoints.down('md')]: {
        fontSize: '2.7em',
      }
    }),
    headingBold: ()=>({
      fontWeight: '800',
    }),
    subtitle: theme=>({
      color: theme.palette.text.primary,
      fontWeight: '400',
      fontSize: '18px',
      textAlign: textAlign || 'center',
      maxWidth: theme.breakpoints.values.md+'px',
      margin: 'auto',
    }),
    subtitleContainer: theme=>({
      marginTop: theme.spacing(8),
      textAlign: 'center',
    })
  }

  const headingStyle = [styles.heading]
  if (bold) {
    headingStyle.push(styles.headingBold)
  }

  return (
    <div css={styles.root}>
      <FormattedTypography variant="h1" sx={headingStyle}>{heading}</FormattedTypography>

      {subtitle &&
        <div css={styles.subtitleContainer}>
          <MultilineTypography component="div" sx={styles.subtitle} ignoreOnXs>{subtitle}</MultilineTypography>
        </div>
      }

      {incentive &&  <Box mt={5}>{incentive}</Box>}

    </div>
  )
}