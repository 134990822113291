import { Avatar, Box, Container, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { RatingStars } from "modules/picasso-ui/present/RatingStars"
import { orElse } from "utils/objectUtils"
import { LandingTestimonials } from "./LandingTestimonials"
import { FormattedTypography } from "modules/picasso-ui/present/text/FormattedText";

const useStyles = makeStyles((theme)=>({
    item: {
        borderRadius: '6px',
        //border: '1px solid ' +  theme.palette.divider,
        maxWidth: theme.breakpoints.values.md,
    },
    text: {
        fontSize: '16px',
        color: theme.palette.text.secondary,
        fontWeight: '400',
    },
    name: {
        fontSize: '16px',
        fontWeight: '600',
    },
}))

export const LandingCustomerVoices = ({items, showStars, starsPosition, showAvatar}) => {

    const classes = useStyles()

    starsPosition = orElse(starsPosition, 'nextToName')

    const renderItem = (i) => {
        return (
            <Box className={classes.item} p={3} display="flex">
            {showAvatar && 
                <Box mr={3}>
                    <Avatar></Avatar>
                </Box>
            }
            <Box flexGrow={1} textAlign="center">
                    {showStars && starsPosition === 'top' && 
                        <Box mb={1}>
                            <RatingStars />
                        </Box>
                    }
                <FormattedTypography component="span" className={classes.text} children={"\""+i.text+"\""}/>
                <Box mt={1}>
                    <Box display="flex" alignItems="center" justifyContent="center">
                        {showAvatar && 
                            <Box mr={1}>
                                <Avatar></Avatar>
                            </Box>
                        }
                        <Typography className={classes.name}>{i.name}</Typography>
                        {showStars && starsPosition === 'nextToName' && 
                            <Box ml={3}>
                                <RatingStars />
                            </Box>
                        }
                    </Box>
                </Box>
            </Box>
        </Box>
        )
    }

    return (
        <Box pt={8} pb={4}>
            <Container maxWidth="md">
                <LandingTestimonials 
                    items={items}
                    renderItem={renderItem}
                />
            </Container>
        </Box>
    )

}